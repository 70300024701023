export const ROUTES = {
  HOME: '/',
  INVEST: '/invest',
  AIRDROPS: '/airdrops',
  VOTING: '/voting',
  SINGLE_VOTE: '/voting/:id',
  UI_LIB: '/ui-lib',
  MIGRATE: '/migrate',
  VENEWO: 'https://dao.neworder.network/venewo',
};
