/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { Web3Context } from 'components/Web3Provider';
import LoadingOverlay from 'react-loading-overlay';

import s from './Deposits.module.scss';
import Deposit, { VeDeposit } from './Deposit/Deposit';

const Deposits = () => {
  const { contracts } = useContext(Web3Context);

  return (
    <LoadingOverlay spinner>
      <table className={s.activeDeposit}>
        <thead>
          <th>Asset</th>
          <th>Current Balance</th>
          <th>Rewards (NEWO)</th>
          <th>APR</th>
          <th>Actions</th>
        </thead>
        <tbody>
          {contracts.VAULTS?.map((vault) => {
            const isVeVault = vault.label.toLowerCase().includes('ve');

            if (isVeVault) {
              return (
                <VeDeposit
                  key={vault.vaultAddress}
                  label={vault.label}
                  tokenAddress={vault.tokenAddress}
                  vaultAddress={vault.vaultAddress}
                  token0={vault.token0}
                  token1={vault.token1}
                />
              );
            }

            return (
              <Deposit
                key={vault.vaultAddress}
                label={vault.label}
                tokenAddress={vault.tokenAddress}
                vaultAddress={vault.vaultAddress}
                token0={vault.token0}
                token1={vault.token1}
              />
            );
          })}
        </tbody>
      </table>

      {/* <div>
          <p className={s.tip}>
            It looks like you don&apos;t have any active deposit right now.
          </p>
          <img
            src={NoDeposit}
            alt="No Active Deposit"
            className={s.noDepositImg}
          />
        </div> */}
    </LoadingOverlay>
  );
};

export default Deposits;
