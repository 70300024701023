import React, { useState, useEffect, useRef, createContext } from 'react';
import Web3 from 'web3';
import MetaMaskOnboarding from '@metamask/onboarding';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AbiItem } from 'web3-utils';
import { logger } from 'services';
import { Network } from 'constants/networks';
import { NOTIFICATION_TYPES, showNotification } from 'components/UILib';
import { MerkleDistributor } from 'constants/abi/types/MerkleDistributor';
import TestERC20 from './TestERC20.json';
import { NETWORK, NetworkContract } from '../../constants';
import merkleDistributorAbi from '../../constants/abi/MerkleDistributor.json';
import { getAirdropInfo } from '../../utils/airdropUtils';
import { NOT_AVAILABLE } from '../../constants/dataConstants';

window.web3 = new Web3(window.ethereum);

const initialState = {
  isInstalled: false,
  isPending: false,
  isConnected: false,
  address: '',
  tokenAmount: '',
  canClaimOdt: false,
  network: Number(window.ethereum ? window.ethereum.networkVersion : '1'),
  isMainnet: false,
  web3: new Web3(window.ethereum),
  getTokenAmount: () => Promise.resolve(''),
  contracts: NETWORK[window.ethereum ? window.ethereum.networkVersion : '1'],
};

interface Web3ContextState {
  isInstalled: boolean;
  isPending: boolean;
  isConnected: boolean;
  address: string;
  tokenAmount: string;
  canClaimOdt: boolean;
  network: number;
  isMainnet: boolean;
  web3?: Web3;
  contracts: NetworkContract;
  getTokenAmount?: () => Promise<number | string>;
  connect?: () => void;
  getBrowserPlugin?: () => void;
  getMerkleDistributorInstance?: () => MerkleDistributor | null;
  manageAccounts?: () => void;
  updateTokenBalance?: () => void;
  changeChainId?: (network: Network) => void;
  resetTokenBalance?: () => void;
}

export const Web3Context = createContext<Web3ContextState>(initialState);

const Web3Provider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<Web3ContextState>(initialState);
  const { isConnected, address } = state;

  const web3 = useRef<Web3>(new Web3(window.ethereum));

  const onMetamaskDisconnect = () => {
    setState((prevState) => ({
      ...initialState,
      isInstalled: prevState.isInstalled,
    }));
  };

  const onMetamaskConnect = (newAddress: string) => {
    setState((prevState) => ({
      ...prevState,
      address: newAddress,
      isConnected: true,
    }));
  };

  const onChainChanged = (chainId: string) => {
    if (!chainId.length) {
      return;
    }

    const chain = parseInt(chainId, 16);
    const isMainnet = chain === 1;

    setState((prevState) => ({
      ...prevState,
      network: chain,
      isMainnet,
    }));
  };

  const updateTokenBalance = async () => {
    if (!state.contracts) {
      return;
    }

    const tokenInstance = await new web3.current.eth.Contract(
      TestERC20.abi as Array<AbiItem>,
      state.contracts.TOKENS.NEWO.V2,
    );

    const balance = await tokenInstance.methods.balanceOf(address).call();
    const formattedBalance = web3.current.utils.fromWei(balance, 'ether');

    setState((prevState) => ({
      ...prevState,
      tokenAmount: formattedBalance.toString(),
    }));
  };

  useEffect(() => {
    if (isConnected) {
      web3.current = new Web3(window.ethereum);
    }
  }, [isConnected]);

  useEffect(() => {
    const networkContracts = NETWORK[state.network];

    // Check if the current network don't have a set of contracts available
    if (!networkContracts && state.network !== 0) {
      // Throw a warning to let the user know to change the network
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        description:
          'We currently do not support this network and the app will not work as expected. Please change it by clicking the network icon on the header',
        lifetime: 10000,
        tag: 'Network',
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        contracts: networkContracts,
      }));
    }

    // eslint-disable-next-line
  }, [state.network]);

  useEffect(() => {
    if (window.ethereum !== undefined) {
      setState((prevState) => ({
        ...prevState,
        isInstalled: true,
      }));

      const { selectedAddress, chainId } = window.ethereum;

      if (selectedAddress) {
        onMetamaskConnect(selectedAddress);
      }

      if (chainId) {
        onChainChanged(chainId);
      }

      window.ethereum.on('connect', async (info: { chainId: string }) => {
        const accounts = await window.ethereum.request({
          method: 'eth_accounts',
        });

        onMetamaskConnect(accounts[0]);
        onChainChanged(info.chainId);
      });

      window.ethereum.on('disconnect', onMetamaskDisconnect);

      window.ethereum.on('chainChanged', onChainChanged);

      window.ethereum.on('accountsChanged', (accounts: Array<string>) => {
        if (accounts.length) {
          onMetamaskConnect(accounts[0]);
        } else {
          onMetamaskDisconnect();
        }
      });

      window.ethereum.on('accountsChanged', (accounts: Array<string>) => {
        if (accounts.length) {
          onMetamaskConnect(accounts[0]);
        } else {
          onMetamaskDisconnect();
        }
      });
    }
  }, []);

  const getMerkleDistributorInstance = (): MerkleDistributor | null => {
    if (!state.contracts || !state.contracts.CONTRACTS.MERKLE_TREE) {
      return null;
    }

    const merkleDistributorInstance = new window.web3.eth.Contract(
      merkleDistributorAbi,
      state.contracts.CONTRACTS.MERKLE_TREE,
    );

    return merkleDistributorInstance;
  };

  useEffect(() => {
    async function asyncEffectWrapper() {
      const airdropInfo = getAirdropInfo(address);
      const distributorInstance = getMerkleDistributorInstance();

      if (airdropInfo && distributorInstance) {
        const wasClaimed = await distributorInstance.methods
          .isClaimed(airdropInfo.index)
          .call();

        setState((prevState) => ({
          ...prevState,
          canClaimOdt: !wasClaimed,
        }));
      }
    }

    if (address && address.length) {
      asyncEffectWrapper();
    }

    // eslint-disable-next-line
  }, [address, state.network]);

  const getTokenAmount = async (): Promise<string> => {
    if (!state.contracts || !state.contracts.TOKENS.NEWO.V2) {
      return NOT_AVAILABLE;
    }

    try {
      const tokenInstance = new web3.current.eth.Contract(
        TestERC20.abi as Array<AbiItem>,
        state.contracts.TOKENS.NEWO.V2,
      );

      const balance = await tokenInstance.methods.balanceOf(address).call();
      const formattedBalance = web3.current.utils.fromWei(balance, 'ether');

      return formattedBalance.toString();
    } catch (e) {
      return NOT_AVAILABLE;
    }
  };

  useEffect(() => {
    async function asyncEffectWrapper() {
      let receivedTokenAmount: Web3ContextState['tokenAmount'] = '';

      if (isConnected) {
        receivedTokenAmount = await getTokenAmount();
      }

      setState((prevState) => ({
        ...prevState,
        tokenAmount: receivedTokenAmount,
      }));
    }

    asyncEffectWrapper();

    // eslint-disable-next-line
  }, [isConnected, state.contracts, address]);

  const getBrowserPlugin = () => {
    const onboarding = new MetaMaskOnboarding();

    onboarding.startOnboarding();
  };

  const setIsPending = (value: Web3ContextState['isPending']) => {
    setState((prevState) => ({
      ...prevState,
      isPending: value,
    }));
  };

  const handleConnect = () => {
    setIsPending(true);
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(() => {
        setIsPending(false);
      })
      .catch(() => {
        setIsPending(false);
      });
  };

  async function manageAccounts() {
    if (isConnected) {
      await window.ethereum.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
    }
  }

  const changeChainId = async (network: Network) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${network.chainId.toString(16)}` }],
        });
      } catch (err: any) {
        if (err.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${network.chainId.toString(16)}`,
                  chainName: network.name,
                  rpcUrls: [network.rpcUrl],
                  nativeCurrency: {
                    name: network.nativeCurrency.name,
                    symbol: network.nativeCurrency.symbol,
                    decimals: network.nativeCurrency.decimals,
                  },
                  blockExplorerUrls: [network.blockExplorerUrl],
                },
              ],
            });
          } catch (error) {
            logger.log(error);
          }
        }
      } finally {
        window.location.reload();
      }
    }
  };

  const resetTokenBalance = () => {
    setState((prevState) => ({
      ...prevState,
      tokenAmount: '',
    }));
  };

  const providerValue = {
    ...state,
    connect: handleConnect,
    getBrowserPlugin,
    getMerkleDistributorInstance,
    manageAccounts,
    updateTokenBalance,
    web3: web3.current,
    getTokenAmount,
    changeChainId,
    resetTokenBalance,
  };

  return (
    <Web3Context.Provider value={providerValue}>
      {children}
    </Web3Context.Provider>
  );
};

export default Web3Provider;
