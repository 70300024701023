import { useContext, useEffect, useState } from 'react';
import { TokenV2 } from 'constants/abi/types/TokenV2';
import coinGeckoApi from 'api/coingeckoApi';
import { logger } from 'services';
import { Web3Context } from 'components/Web3Provider';
import tokenV2Abi from '../constants/abi/TokenV2.json';
import { YEAR_IN_SECONDS } from '../constants';
import useVault from './useVault';

interface UseSlpVaultData {
  userVaultBalance: string;
  vaultBalance: string;
  tokenBalance: string;
  loading: boolean;
  apr: string;
  tokenSymbol: string;
  stake: (amount: string) => Promise<void>;
  allowance: string;
  approve: () => Promise<void>;
  earned: string;
  getReward: () => Promise<void>;
  exit: () => Promise<void>;
  withdraw: (amount: string) => Promise<void>;
}

const yearInSeconds = YEAR_IN_SECONDS;

// lpTokenAddress is the used token to produce LP tokens
const useSlpVault = (
  vaultAddress: string,
  tokenAddress: string,
  lpTokenAddress: string,
): UseSlpVaultData => {
  const { contracts } = useContext(Web3Context);
  const {
    userVaultBalance,
    vaultBalance,
    tokenBalance,
    loading,
    tokenSymbol,
    stake,
    allowance,
    approve,
    earned,
    getReward,
    exit,
    withdraw,
    vaultContract,
    tokenContract,
  } = useVault(vaultAddress, tokenAddress);

  const [apr, setApr] = useState('');
  const [lpTokenContract, setLpTokenContract] = useState<TokenV2 | null>(null);
  const [usdcTokenContract, setUsdcTokenContract] = useState<TokenV2 | null>(
    null,
  );

  useEffect(() => {
    if (lpTokenAddress) {
      const tokenInstance = new window.web3.eth.Contract(
        tokenV2Abi,
        lpTokenAddress,
      );

      setLpTokenContract(tokenInstance);
    }
  }, [lpTokenAddress]);

  useEffect(() => {
    if (contracts.TOKENS.USDC) {
      const tokenInstance = new window.web3.eth.Contract(
        tokenV2Abi,
        contracts.TOKENS.USDC,
      );

      setUsdcTokenContract(tokenInstance);
    }
  }, [contracts]);

  const getLatestNewoPrice = async () => {
    let currentPrice = 0;

    await coinGeckoApi
      .get(`/coins/new-order`)
      .then((res) => {
        currentPrice = res.data.market_data.current_price.usd;
      })
      .catch((err) => {
        logger.log(err);
      });

    return currentPrice;
  };

  const getApr = async () => {
    if (
      !lpTokenContract ||
      !usdcTokenContract ||
      !vaultContract ||
      !tokenContract
    )
      return;

    const totalLpToken = await lpTokenContract.methods
      .balanceOf(tokenAddress)
      .call();
    const convertedTotalLpToken = window.web3.utils.fromWei(
      totalLpToken,
      'ether',
    );

    const totalUsdc = await usdcTokenContract?.methods
      .balanceOf(tokenAddress)
      .call();
    const convertedTotalUsdc = window.web3.utils.fromWei(
      totalUsdc,
      'picoether',
    );

    const rewardRate = await vaultContract?.methods.rewardRate().call();
    const convertedRewardRate = window.web3.utils.fromWei(rewardRate, 'ether');

    const tokenPrice = await getLatestNewoPrice();

    const totalSlpToken = await tokenContract?.methods.totalSupply().call();
    const convertedTotalSlpToken = window.web3.utils.fromWei(
      totalSlpToken,
      'ether',
    );

    const totalVaultBalance = await vaultContract?.methods.totalSupply().call();
    const convertedTotalVaultBalance = window.web3.utils.fromWei(
      totalVaultBalance,
      'ether',
    );

    // SLP Vault APR
    // tvl = (totalUsdc + (totalNewo * tokenPrice) * slpVaultTotalSupply) / totalSupplyOfSlpToken
    // apr = (rewardRate * tokenPrice / tvl) * yearInSeconds * 100
    const tvl =
      ((Number(convertedTotalUsdc) +
        Number(convertedTotalLpToken) * tokenPrice) *
        Number(convertedTotalVaultBalance)) /
      Number(convertedTotalSlpToken);

    const calculatedApr =
      ((Number(convertedRewardRate) * tokenPrice) / tvl) * yearInSeconds * 100;

    const finalApr = calculatedApr.toFixed(2);

    setApr(finalApr);
  };

  useEffect(() => {
    if (
      vaultContract &&
      lpTokenContract &&
      usdcTokenContract &&
      tokenContract
    ) {
      getApr();
    }

    // eslint-disable-next-line
  }, [vaultContract, lpTokenContract, usdcTokenContract, tokenContract]);

  return {
    userVaultBalance,
    vaultBalance,
    tokenBalance,
    loading,
    apr,
    tokenSymbol,
    stake,
    allowance,
    approve,
    earned,
    getReward,
    exit,
    withdraw,
  };
};

export default useSlpVault;
