import React from 'react';
import cn from 'classnames';

import { Button } from 'components/UILib';
import { tabsConfig } from './module/tabsConfig';

import { Tabs } from '../../components/UILib/Tabs';

import s from './HomePage.module.scss';

export interface HomePageProps {
  className?: string;
}

const HomePage: React.FC<HomePageProps> = (props) => {
  const { className } = props;

  return (
    <div className={cn(s.root, className)}>
      <div className={s.headerButtons}>
        <Button
          href="https://linktr.ee/neworderdao"
          target="_blank"
          rel="noopeneer noreferrer"
          variant="monochrome"
        >
          Community
        </Button>
        <Button
          href="https://app.dework.xyz/o/new-order-2JfIVgH1tsjmS7SLTmjLT4"
          target="_blank"
          rel="noopeneer noreferrer"
          variant="monochrome"
        >
          Contributor Bounties
        </Button>
      </div>

      <Tabs tabs={tabsConfig} className={s.tabs} />
    </div>
  );
};

export default HomePage;
