import React, { useContext } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import useOneWaySwap from 'hooks/useOneWaySwap';
import useToken from 'hooks/useToken';
import { Web3Context } from 'components/Web3Provider';
import { useHistory } from 'react-router-dom';
import {
  Icon,
  Button,
  PageTitle,
  TagButton,
  RegularAccordion,
  Notice,
} from 'components/UILib';
import { Tabs } from 'components/UILib/Tabs';
import styles from './SwapPage.module.scss';
import MetamaskDisconnected from '../../images/MetamaskDisconnected.svg';

const NewoV1 = (
  <TagButton color="silver" badge uppercase tail="v1" active>
    NEWO
  </TagButton>
);

function Step({
  header,
  children,
  enabled = false,
  completed = false,
  open = false,
}) {
  return (
    <RegularAccordion
      header={
        <span>
          <Icon name={!completed ? 'warning' : 'success'} /> {header}
        </span>
      }
      className={styles.accordian}
      isOpen={open}
      disabled={!enabled}
    >
      <div className={styles.swapBox}>{children}</div>
    </RegularAccordion>
  );
}

const EthereumSwap: React.FC = () => {
  const history = useHistory();

  const { contracts, isConnected } = useContext(Web3Context);
  const {
    swap,
    allowance,
    approve,
    loading,
    outstandingDepositsOrRewards,
    outstandingSLPtokens,
    outstandingNEWOv1Tokens,
    swapReady,
  } = useOneWaySwap();
  const { balance, updateBalance } = useToken(contracts.TOKENS.NEWO.V1);

  // A constant that checks whether the user still has NEWO balance and vault deposits
  const NO_V1_TOKENS_DETECTED =
    !outstandingNEWOv1Tokens && !outstandingDepositsOrRewards;

  const migrateTokens = async () => {
    await swap(balance);
    await updateBalance();
  };

  return (
    <div>
      <PageTitle className={styles.header}>
        Upgrade to NEWO V2 on Ethereum
      </PageTitle>

      <p className={styles.description}>
        All <code>NEWO V1</code> tokens{' '}
        <i>
          <u>must</u> be migrated manually
        </i>{' '}
        to the recently released <code>NEWO V2</code> using this tool. With this
        option, you will receive the same amount of <code>NEWO V2</code> tokens
        instantly on the ethereum network.
      </p>

      {NO_V1_TOKENS_DETECTED && (
        <div className={styles.accordian}>
          <div className={styles.panel}>
            <h2>
              <Icon name="warning" /> NO <code>NEWO V1</code> TOKENS DETECTED
            </h2>
            <p>
              There are no {NewoV1} in the connected wallet. Please confirm you
              are connected with the correct account.
            </p>
          </div>
        </div>
      )}

      {isConnected && !NO_V1_TOKENS_DETECTED && (
        <LoadingOverlay active={loading} spinner className={styles.steps}>
          <Step
            header="Step 1: Unstake + Claim"
            enabled={outstandingDepositsOrRewards}
            completed={!outstandingDepositsOrRewards}
            open={outstandingDepositsOrRewards}
          >
            <span>
              Remove <code>NEWO V1</code> from all single-sided, Sushi LP, and
              governance vaults. Claim all outstanding rewards.
            </span>
            <div className={styles.swapDetails}>
              <Button
                onClick={function handleClick() {
                  history.push('/invest#active-deposits');
                }}
                className={styles.swapButton}
                disabled={!outstandingDepositsOrRewards}
              >
                Go To Vaults
              </Button>
            </div>
          </Step>

          <Step
            header="Step 2: Remove Sushi LP Positions"
            enabled={outstandingSLPtokens || outstandingDepositsOrRewards}
            completed={!outstandingSLPtokens && !outstandingDepositsOrRewards}
          >
            <span>
              Remove {NewoV1} from all single-sided, Sushi LP, and governance
              vaults. Claim all outstanding rewards.
            </span>
            <div className={styles.swapDetails}>
              <Button
                className={styles.swapButton}
                disabled={Number(balance) === 0}
                onClick={function handleClick() {
                  window.open(
                    'https://app.sushi.com/add/0x1b890fd37cd50bea59346fc2f8ddb7cd9f5fabd5/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48com',
                  );
                }}
              >
                Go To Sushi LP
              </Button>
            </div>
          </Step>

          <Step
            header="Step 3: Approve NEWO v1 to v2 Migration"
            enabled={!outstandingDepositsOrRewards && !outstandingSLPtokens}
            completed={!outstandingDepositsOrRewards && !outstandingSLPtokens}
          >
            <div className={styles.swapDetails}>
              <span className={styles.label}>Tokens requiring approval:</span>
              <span className={styles.value}>
                {new Intl.NumberFormat().format(Number(balance))}{' '}
                <TagButton color="silver" badge uppercase tail="v1">
                  NEWO
                </TagButton>
              </span>
            </div>

            {Number(allowance) <= 0 && (
              <Button
                onClick={approve}
                className={styles.swapButton}
                disabled={Number(balance) <= 0}
              >
                Approve
              </Button>
            )}
          </Step>

          <Step
            header={`Step 4: Migrate Tokens (Total:
                  ${new Intl.NumberFormat().format(Number(balance))} NEWO)`}
            enabled={swapReady}
            completed={swapReady}
          >
            <div className={styles.swapDetails}>
              <span className={styles.label}>
                Total tokens available for migration:
              </span>
              <span className={styles.value}>
                {new Intl.NumberFormat().format(Number(balance))}{' '}
                <TagButton color="silver" badge uppercase tail="v1">
                  NEWO
                </TagButton>
              </span>
            </div>

            <Button
              onClick={migrateTokens}
              className={styles.swapButton}
              disabled={Number(balance) <= 0}
            >
              Migrate
            </Button>
          </Step>
        </LoadingOverlay>
      )}
    </div>
  );
};

const AvalancheSwap = () => {
  const history = useHistory();
  const { contracts, isConnected } = useContext(Web3Context);
  const {
    allowance,
    approve,
    loading,
    outstandingDepositsOrRewards,
    outstandingSLPtokens,
    outstandingNEWOv1Tokens,
    swapReady,
    burn,
  } = useOneWaySwap();
  const { balance, updateBalance } = useToken(contracts.TOKENS.NEWO.V1);

  // A constant that checks whether the user still has NEWO balance and vault deposits
  const NO_V1_TOKENS_DETECTED =
    !outstandingNEWOv1Tokens && !outstandingDepositsOrRewards;

  const migrateTokens = async () => {
    await burn(balance, '43114');
    await updateBalance();
  };

  return (
    <div>
      <PageTitle className={styles.header}>
        Upgrade to NEWO V2 and receive it on Avalanche
      </PageTitle>

      <p className={styles.description}>
        All <code>NEWO V1</code> tokens{' '}
        <i>
          <u>must</u> be migrated manually
        </i>{' '}
        to the recently released <code>NEWO V2</code> using this tool.
        <Notice className={styles.migrationNotice}>
          <h2>Important Notice for Avalanche Migration:</h2>

          <p>
            With this option, you can bridge your <code>NEWO v1</code> tokens to
            avalanche. Once your transaction is successful, open a ticket on our
            discord and send us the transaction hash so we can airdrop the same
            amount of tokens to your address on avalanche
          </p>

          <strong>
            This action <u>cannot</u> be cancelled or reversed!
          </strong>
        </Notice>
      </p>

      {NO_V1_TOKENS_DETECTED && (
        <div className={styles.accordian}>
          <div className={styles.panel}>
            <h2>
              <Icon name="warning" /> NO <code>NEWO V1</code> TOKENS DETECTED
            </h2>
            <p>
              There are no {NewoV1} in the connected wallet. Please confirm you
              are connected with the correct account.
            </p>
          </div>
        </div>
      )}

      {isConnected && !NO_V1_TOKENS_DETECTED && (
        <LoadingOverlay active={loading} spinner className={styles.steps}>
          <Step
            header="Step 1: Unstake + Claim"
            enabled={outstandingDepositsOrRewards}
            completed={!outstandingDepositsOrRewards}
            open={outstandingDepositsOrRewards}
          >
            <span>
              Remove <code>NEWO V1</code> from all single-sided, Sushi LP, and
              governance vaults. Claim all outstanding rewards.
            </span>
            <div className={styles.swapDetails}>
              <Button
                onClick={function handleClick() {
                  history.push('/invest#active-deposits');
                }}
                className={styles.swapButton}
                disabled={!outstandingDepositsOrRewards}
              >
                Go To Vaults
              </Button>
            </div>
          </Step>

          <Step
            header="Step 2: Remove Sushi LP Positions"
            enabled={outstandingSLPtokens || outstandingDepositsOrRewards}
            completed={!outstandingSLPtokens && !outstandingDepositsOrRewards}
          >
            <span>
              Remove {NewoV1} from all single-sided, Sushi LP, and governance
              vaults. Claim all outstanding rewards.
            </span>
            <div className={styles.swapDetails}>
              <Button
                className={styles.swapButton}
                disabled={Number(balance) === 0}
                onClick={function handleClick() {
                  window.open(
                    'https://app.sushi.com/add/0x1b890fd37cd50bea59346fc2f8ddb7cd9f5fabd5/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48com',
                  );
                }}
              >
                Go To Sushi LP
              </Button>
            </div>
          </Step>

          <Step
            header="Step 3: Approve NEWO v1 to v2 Migration"
            enabled={!outstandingDepositsOrRewards && !outstandingSLPtokens}
            completed={!outstandingDepositsOrRewards && !outstandingSLPtokens}
          >
            <div className={styles.swapDetails}>
              <span className={styles.label}>Tokens requiring approval:</span>
              <span className={styles.value}>
                {new Intl.NumberFormat().format(Number(balance))}{' '}
                <TagButton color="silver" badge uppercase tail="v1">
                  NEWO
                </TagButton>
              </span>
            </div>

            {Number(allowance) <= 0 && (
              <Button
                onClick={approve}
                className={styles.swapButton}
                disabled={Number(balance) <= 0}
              >
                Approve
              </Button>
            )}
          </Step>

          <Step
            header={`Step 4: Migrate Tokens (Total:
                  ${new Intl.NumberFormat().format(Number(balance))} NEWO)`}
            enabled={swapReady}
            completed={swapReady}
          >
            <div className={styles.swapDetails}>
              <span className={styles.label}>
                Total tokens available for migration:
              </span>
              <span className={styles.value}>
                {new Intl.NumberFormat().format(Number(balance))}{' '}
                <TagButton color="silver" badge uppercase tail="v1">
                  NEWO
                </TagButton>
              </span>
            </div>

            <Button
              onClick={migrateTokens}
              className={styles.swapButton}
              disabled={Number(balance) <= 0}
            >
              Migrate
            </Button>
          </Step>
        </LoadingOverlay>
      )}
    </div>
  );
};

const SwapPage = () => {
  const { isConnected } = useContext(Web3Context);

  return (
    <div className={styles.swapPage}>
      {!isConnected ? (
        <div>
          <p className={styles.description}>
            Please connect using metamask to continue.
          </p>
          <img
            src={MetamaskDisconnected}
            alt="Metamask not connected"
            className={styles.metamask}
          />
        </div>
      ) : (
        <Tabs
          tabs={[
            {
              id: 'ethereum',
              label: 'Ethereum',
              content: <EthereumSwap />,
            },
            {
              id: 'avalanche',
              label: 'Avalanche',
              content: <AvalancheSwap />,
            },
          ]}
        />
      )}
    </div>
  );
};

export default SwapPage;
