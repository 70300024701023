// Utitilty functions that relates to ethereum

import Web3 from 'web3';

window.web3 = new Web3(window.ethereum);

export const isContract = async (address: string): Promise<boolean> => {
  const contractCode = await window.web3.eth.getCode(address);

  return contractCode !== '0x';
};
