import React from 'react';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';
import removeMd from 'remove-markdown';
import { logger } from 'services';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import mediumApi from 'api/mediumApi';
import frogsAnonApi from 'api/frogsAnonApi';

import PlaceholderImage from 'images/placeholder-image.png';

import { NewsTileTitle } from '../NewsTileTitle';
import { NewsCard } from '../../../../components/NewsCard';
import { TagButton, TileContainer } from '../../../../components/UILib';

// import {
//   setVideos,
//   // selectVideos,
//   // selectArticles,
// } from '../../../../store/news';

import { NEWS_TYPES } from '../../../../store/types';
// import { VideoResponse } from '../../../../store/news/types';

// import { request } from '../../../../utils/apiUtils';

import s from './Websites.module.scss';

interface Section {
  label: string;
  type: NEWS_TYPES | null;
}

const FILTERS_CONFIG: Section[] = [
  {
    label: 'All',
    type: null,
  },
  // {
  //   label: 'UPDATES',
  //   type: NEWS_TYPES.UPDATES,
  // },
  {
    label: 'Articles',
    type: NEWS_TYPES.ARTICLE,
  },
  {
    label: 'Videos',
    type: NEWS_TYPES.VIDEO,
  },
];

interface WebsitesProps {
  className?: string;
}

// const YOUTUBE_API_URL = `https://www.googleapis.com/youtube/v3/search/?part=snippet&channelId=UCoNxg5l_2Gujke_Spm41F8Q&videoCaption=any&key=${GOOGLE_API_KEY}`;

export const Websites: React.FC<WebsitesProps> = ({ className }) => {
  const [section, setSection] = React.useState(FILTERS_CONFIG[0].type);
  const [articles, setArticles] = React.useState<any[]>([]);

  const getArticles = async () => {
    const frogsAnonContent = await frogsAnonApi
      .getEntries({
        content_type: 'blogPost',
        order: '-fields.publishDate',
      })
      .then((res: any) => {
        const data = res.items;

        if (!data) return null;

        return data.map((article) => ({
          id: article.sys.id,
          title: article.fields.title,
          type: NEWS_TYPES.ARTICLE,
          refSource: 'Frogs Anonymous',
          dateTime: article.fields.publishDate,
          newsText: removeMd(article.fields.leadingParagraph).replace('\n', ''),
          webLink: `https://frogsanon.neworder.network/articles/${article.fields.slug}/
          `,
          mediaLink: `https:${article.fields.heroImage.fields.file.url}`,
          digest: article.sys.id,
        }));
      })

      .catch((err) => {
        logger.log(err);
      });

    const mediumContent = await mediumApi
      .get('')
      .then((res) => {
        const data = res.data.items;

        if (!data) return null;

        return data.map((article) => ({
          id: article.guid,
          title: article.title,
          type: NEWS_TYPES.ARTICLE,
          refSource: 'Medium.com',
          dateTime: article.pubDate,
          newsText: removeMd(article.content).replace('\n', ''),
          webLink: article.link,
          // mediaLink: article.thumbnail,
          mediaLink: article?.thumbnail?.includes('cdn-images')
            ? article.thumbnail
            : PlaceholderImage,
          digest: article.guid,
        }));
      })
      .catch((err) => {
        logger.log(err);
      });

    setArticles([...mediumContent, ...frogsAnonContent]);
  };

  React.useEffect(() => {
    getArticles();
  }, []);

  function renderTags() {
    return FILTERS_CONFIG.map((item) => {
      const { label, type } = item;

      return (
        <TagButton
          key={label}
          active={section === type}
          onClick={() => setSection(type)}
          color="green"
        >
          {label}
        </TagButton>
      );
    });
  }

  function renderTitle() {
    return (
      <NewsTileTitle icon="square-rss" title="Updates">
        {renderTags()}
      </NewsTileTitle>
    );
  }

  function formatDate(date: number | string) {
    return dayjs(new Date(date)).format('DD.MM.YYYY HH:mm');
  }

  function renderNews() {
    const allNews = [...articles];
    const sortedByDate = orderBy(allNews, 'dateTime', 'desc');
    const filteredNew = sortedByDate.filter((newsItem) => {
      if (section === null) {
        return true;
      }

      return newsItem.type === section;
    });

    return filteredNew.map((newsItem) => {
      const { id, dateTime } = newsItem;
      const formattedDate = formatDate(dateTime);

      return (
        <NewsCard
          key={id}
          className={s.newsCard}
          {...newsItem}
          dateTime={formattedDate}
        />
      );
    });
  }

  return (
    <TileContainer title={renderTitle()} size="md" className={className}>
      {articles.length > 0 ? (
        renderNews()
      ) : (
        <SkeletonTheme color="#202020" highlightColor="#343434">
          {Array.from(Array(10).keys()).map((key) => (
            <Skeleton key={key} height="100px" count={8} duration={1.8} />
          ))}
        </SkeletonTheme>
      )}
    </TileContainer>
  );
};
