/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import cn from 'classnames';
import VaultComponent, { VeVault } from 'components/Vault/Vault';
import { Web3Context } from 'components/Web3Provider';
import { Redirect } from 'react-router';
import s from './DepositInfo.module.scss';

export interface DepositInfoProps {
  className?: string;
}

export const DepositInfo: React.FC<DepositInfoProps> = (props) => {
  const { contracts } = useContext(Web3Context);

  const { className } = props;

  if (!contracts) {
    return <Redirect to="/" />;
  }

  return (
    <div className={cn(s.root, className)}>
      {contracts &&
        contracts.VAULTS &&
        contracts.VAULTS.map((vault) => {
          const isVeVault = vault.label.toLowerCase().includes('ve');

          if (isVeVault) {
            return (
              <VeVault
                key={vault.vaultAddress}
                vaultLabel={vault.label}
                vaultAddress={vault.vaultAddress}
                tokenAddress={vault.tokenAddress}
                token0={vault.token0}
                token1={vault.token1}
              />
            );
          }

          return (
            <VaultComponent
              key={vault.vaultAddress}
              vaultLabel={vault.label}
              vaultAddress={vault.vaultAddress}
              tokenAddress={vault.tokenAddress}
              token0={vault.token0}
              token1={vault.token1}
            />
          );
        })}
    </div>
  );
};
