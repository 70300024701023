import { NOTIFICATION_TYPES, showNotification } from 'components/UILib';
import { Web3Context } from 'components/Web3Provider';
import { tokenUnit } from 'constants/token';
import { MAX_INTEGER } from 'ethereumjs-util';
import { useContext, useEffect, useState } from 'react';
import { getTokenPrice } from 'utils/token';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import tokenV2Abi from '../constants/abi/TokenV2.json';
import veVaultAbi from '../constants/abi/VeVault.json';

window.web3 = new Web3(window.ethereum);

interface Token {
  address: string;
  coingeckoId: string;
}

const yearInSeconds = 31536000;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useVeVault = (
  vaultAddress: string,
  tokenAddress: string,
  token0?: Token,
  token1?: Token,
) => {
  const { address, updateTokenBalance } = useContext(Web3Context);

  const [veVaultContract, setVeVaultContract] = useState<Contract | null>(null);
  const [tokenContract, setTokenContract] = useState<Contract | null>(null);

  const [loading, setLoading] = useState(false);
  const [userVaultBalance, setUserVaultBalance] = useState('');
  const [vaultBalance, setVaultBalance] = useState('');
  const [tokenBalance, setTokenBalance] = useState('');
  const [allowance, setAllowance] = useState('');
  const [earned, setEarned] = useState('');
  const [apr, setApr] = useState('');
  const [tvl, setTvl] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');

  useEffect(() => {
    if (vaultAddress) {
      const vaultInstance = new window.web3.eth.Contract(
        veVaultAbi,
        vaultAddress,
      );

      setVeVaultContract(vaultInstance);
    }
  }, [vaultAddress]);

  useEffect(() => {
    if (tokenAddress) {
      const tokenInstance = new window.web3.eth.Contract(
        tokenV2Abi,
        tokenAddress,
      );

      setTokenContract(tokenInstance);
    }
  }, [tokenAddress]);

  const getTokenSymbol = async () => {
    const symbol = await tokenContract?.methods.symbol().call();

    setTokenSymbol(symbol as string);
  };

  useEffect(() => {
    if (tokenContract) {
      getTokenSymbol();
    }

    // eslint-disable-next-line
  }, [tokenContract]);

  const getUserVaultBalance = async () => {
    if (!address) return;

    const balance = await veVaultContract?.methods.assetBalanceOf(address).call();
    const convertedBalance = window.web3.utils.fromWei(balance, 'ether');

    setUserVaultBalance(convertedBalance);
  };

  const getVaultBalance = async () => {
    const balance = await veVaultContract?.methods.totalSupply().call();
    const convertedBalance = window.web3.utils.fromWei(balance, 'ether');

    setVaultBalance(convertedBalance);
  };

  const getTokenBalance = async () => {
    if (!address) return;

    const balance = await tokenContract?.methods.balanceOf(address).call();
    const convertedBalance = window.web3.utils.fromWei(balance, 'ether');

    setTokenBalance(convertedBalance);
  };

  const getAllowance = async () => {
    if (!address) return;

    const tokenAllowance = await tokenContract?.methods
      .allowance(address, vaultAddress)
      .call();

    const convertedAllowance = window.web3.utils.fromWei(
      tokenAllowance,
      'ether',
    );

    setAllowance(convertedAllowance);
  };

  const getEarned = async () => {
    // Fetches the reward amount of the user that has deposited
    if (!address) return;

    const earnedRewards = await veVaultContract?.methods.earned(address).call();
    const convertedEarnedRewards = window.web3.utils.fromWei(
      earnedRewards,
      'ether',
    );

    setEarned(convertedEarnedRewards);
  };

  const getTvl = async () => {
    let computedTvl = 0;

    if (token0 && token1) {
      // Initialize token0 and token1 contract
      const token0Instance = new window.web3.eth.Contract(
        tokenV2Abi,
        token0.address,
      );

      const token1Instance = new window.web3.eth.Contract(
        tokenV2Abi,
        token1.address,
      );

      // Get the decimal of the token
      const token0Decimals = await token0Instance.methods.decimals().call();
      const token0Unit = tokenUnit[token0Decimals];

      const token1Decimals = await token1Instance.methods.decimals().call();
      const token1Unit = tokenUnit[token1Decimals];

      // Get the usd price of token0 and token1 and rewards token
      const token0Price = await getTokenPrice(token0.coingeckoId);
      const token1Price = await getTokenPrice(token1.coingeckoId);

      // Get the balance of the LP contract on token0
      const lpTokenBalance0 = await token0Instance.methods
        .balanceOf(tokenAddress)
        .call();
      const convertedLpTokenBalance0 = Number(
        window.web3.utils.fromWei(lpTokenBalance0, token0Unit),
      );

      const lpTokenBalance1 = await token1Instance.methods
        .balanceOf(tokenAddress)
        .call();
      const convertedLpTokenBalance1 = Number(
        window.web3.utils.fromWei(lpTokenBalance1, token1Unit),
      );

      // Get the total supply of the vault
      const vaultTotalSupply = await veVaultContract?.methods
        .totalSupply()
        .call();
      const convertedVaultTotalSupply = Number(
        window.web3.utils.fromWei(vaultTotalSupply, 'ether'),
      );

      // Get the total supply of the lp token
      const tokenTotalSupply = await tokenContract?.methods
        .totalSupply()
        .call();
      const convertedTokenTotalSupply = Number(
        window.web3.utils.fromWei(tokenTotalSupply, 'ether'),
      );

      computedTvl =
        ((convertedLpTokenBalance0 * token0Price +
          convertedLpTokenBalance1 * token1Price) *
          convertedVaultTotalSupply) /
        convertedTokenTotalSupply;
    } else {
      const vaultTotalSupply = await veVaultContract?.methods
        .totalSupply()
        .call();
      const convertedVaultTotalSupply = Number(
        window.web3.utils.fromWei(vaultTotalSupply, 'ether'),
      );

      // const tokenPrice = await getTokenPrice(tokenAddress);

      computedTvl = convertedVaultTotalSupply * 0;
    }

    setTvl(computedTvl.toString());

    return Number(computedTvl);
  };

  const getApr = async () => {
    if (!veVaultContract) return;

    let calculatedApr = 0;

    if (token0 && token1) {
      // const rewardToken = await vaultContract?.methods.rewardsToken().call();
      // Since most of the vaults use NEWO as rewards
      const rewardTokenPrice = await getTokenPrice('new-order');

      // Get the reward rate of the vault
      const rewardRate = await veVaultContract?.methods.rewardRate().call();
      const convertedRewardRate = Number(
        window.web3.utils.fromWei(rewardRate, 'ether'),
      );

      const lpTvl = await getTvl();

      calculatedApr =
        (convertedRewardRate * rewardTokenPrice * yearInSeconds * 100) / lpTvl;
    } else {
      const rewardRate = await veVaultContract.methods.rewardRate().call();
      const convertedRewardRate = Number(
        window.web3.utils.fromWei(rewardRate, 'ether'),
      );

      const totalSupply = await veVaultContract.methods.totalSupply().call();
      const convertedTotalSupply = Number(
        window.web3.utils.fromWei(totalSupply, 'ether'),
      );

      // Single side APR
      // 100 * yearInSeconds * (rewardRate / totalSupply)

      calculatedApr =
        (Number(convertedRewardRate) / Number(convertedTotalSupply)) *
        yearInSeconds *
        100;
    }

    const finalApr = calculatedApr.toFixed(2);

    if (Number.isNaN(Number)) return;

    setApr(finalApr);
  };

  const updateBalances = async () => {
    // Function to update all balances that show on the UI
    Promise.all([
      getUserVaultBalance(),
      getVaultBalance(),
      getTokenBalance(),
      getAllowance(),
      getEarned(),
      getApr(),
      getTvl(),
    ]);

    if (updateTokenBalance) {
      updateTokenBalance();
    }
  };

  useEffect(() => {
    // Update balances on first load
    if (tokenContract && veVaultContract && address) {
      updateBalances();
    }

    // eslint-disable-next-line
  }, [tokenContract, veVaultContract, address]);

  const deposit = async (amount: string) => {
    const tag = 'VAULT DEPOSIT';

    if (!veVaultContract && !address) return;

    setLoading(true);

    try {
      const amountInEther = window.web3.utils.toWei(amount, 'ether');
      const amountBn = window.web3.utils.toBN(amountInEther);

      await veVaultContract?.methods
        .deposit(amountBn, address)
        .send({ from: address });
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Deposit successfully',
        lifetime: 5000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Deposit failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      await updateBalances();
      setLoading(false);
    }
  };

  const approve = async () => {
    const tag = 'Token Approval';

    setLoading(true);

    try {
      await tokenContract?.methods
        .approve(vaultAddress, MAX_INTEGER)
        .send({ from: address });

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Tokens approved successfully',
        lifetime: 5000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Token approval failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      await updateBalances();
      setLoading(false);
    }
  };

  const getReward = async () => {
    // Function to withdraw the rewards
    const tag = 'Claim Rewards';

    setLoading(true);

    try {
      await veVaultContract?.methods.getReward().send({ from: address });
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Rewards claimed successfully',
        lifetime: 5000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Reward claim failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      await updateBalances();
      setLoading(false);
    }
  };

  const exit = async () => {
    // Function to withdraw deposits and withdraw rewards
    const tag = 'Withdraw';

    setLoading(true);

    try {
      await veVaultContract?.methods.exit().send({ from: address });
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Withdrew assets successfully',
        lifetime: 5000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Withdrawal of assets failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      await updateBalances();
      setLoading(false);
    }
  };

  const withdraw = async (amount: string) => {
    // Function to withdraw deposits and withdraw rewards
    const tag = 'Withdraw';

    setLoading(true);

    try {
      const amountInEther = window.web3.utils.toWei(amount, 'ether');
      const amountBn = window.web3.utils.toBN(amountInEther);

      await veVaultContract?.methods
        .withdraw(amountBn, address, address)
        .send({ from: address });
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Withdrew assets successfully',
        lifetime: 5000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Withdrawal of assets failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      await updateBalances();
      setLoading(false);
    }
  };

  return {
    deposit,
    approve,
    getReward,
    exit,
    withdraw,
    userVaultBalance,
    vaultBalance,
    tokenBalance,
    loading,
    apr,
    allowance,
    earned,
    veVaultContract,
    tokenContract,
    tvl,
    tokenSymbol,
  };
};

export default useVeVault;
