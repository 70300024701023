// A hook that swaps v1 token to v2 token

import { NOTIFICATION_TYPES, showNotification } from 'components/UILib';
import { Web3Context } from 'components/Web3Provider';
import { MAX_INTEGER } from 'ethereumjs-util';
import BigNumber from 'bignumber.js';
import { useContext, useEffect, useState } from 'react';
import Web3 from 'web3';

import slpTokenAbi from 'components/SLPToken/SLPToken.json';
import { isContract } from 'utils/ethereum';

import oneWaySwapAbi from '../constants/abi/OneWaySwap.json';
import tokenV1Abi from '../constants/abi/TokenV1.json';

import { OneWaySwap } from '../constants/abi/types/OneWaySwap';
import { TokenV1 } from '../constants/abi/types/TokenV1';
import { SLPToken } from '../constants/abi/types/SLPToken';
import useVault from './useVault';
import useSlpVault from './useSlpVault';

window.web3 = new Web3(window.ethereum);

type IOneWaySwap = {
  swap: (amount: string) => void;
  approve: () => void;
  burn: (amount: string, why: string) => void;
  allowance: string;
  loading: boolean;

  outstandingDepositsOrRewards: boolean;
  outstandingSLPtokens: boolean;
  outstandingNEWOv1Tokens: boolean;

  swapReady: boolean;

  vaultBalance: number;
  vaultRewards: number;
  slpBalance: number;
  slpRewards: number;
};

const useOneWaySwap = (): IOneWaySwap => {
  const { address, updateTokenBalance, contracts } =
    useContext(Web3Context) || {};
  const { userVaultBalance, earned } = useVault(
    contracts.CONTRACTS.SINGLE_SIDE_STAKING.V1,
    contracts.TOKENS.NEWO.V1,
  );
  const {
    userVaultBalance: slpUserVaultBalance,
    earned: slpEarned,
  } = useSlpVault(
    contracts.CONTRACTS.SUSHI_LP_STAKING.V1,
    contracts.TOKENS.NEWO_USDC_SLP.V1,
    contracts.TOKENS.NEWO.V1,
  );

  const [loading, setLoading] = useState(false);

  const [
    oneWaySwapContract,
    setOneWaySwapContract,
  ] = useState<OneWaySwap | null>(null);
  const [tokenContract, setTokenContract] = useState<TokenV1 | null>(null);
  const [allowance, setAllowance] = useState('');
  const [vaultBalance, setVaultBalance] = useState(0);
  const [vaultRewards, setVaultRewards] = useState(0);
  const [slpBalance, setSLPVaultBalance] = useState(0);
  const [slpRewards, setSLPVaultRewards] = useState(0);
  const [slpTokenInstance, setSLPTokenInstance] = useState<SLPToken | null>();

  const [
    outstandingDepositsOrRewards,
    setOutstandingDepositsOrRewards,
  ] = useState(false);

  const [outstandingSLPtokens, setOutstandingSLPtokens] = useState(false);
  const [outstandingNEWOv1Tokens, setOutstandingNEWOv1Tokens] = useState(true);

  const [swapReady, setSwapReady] = useState(false);

  useEffect(() => {
    if (
      vaultBalance === 0 &&
      vaultRewards === 0 &&
      slpBalance === 0 &&
      slpRewards === 0
    ) {
      setOutstandingDepositsOrRewards(false);
    } else {
      setOutstandingDepositsOrRewards(true);
    }
  }, [vaultBalance, vaultRewards, slpBalance, slpRewards]);

  useEffect(() => {
    // Check if user has v1 token balance and
    // no more deposits or lp tokens and has approved the swap as spender
    if (
      outstandingNEWOv1Tokens &&
      !outstandingDepositsOrRewards &&
      Number(allowance) !== 0 &&
      !outstandingSLPtokens
    ) {
      setSwapReady(true);
    } else {
      setSwapReady(false);
    }
  }, [
    outstandingDepositsOrRewards,
    outstandingSLPtokens,
    allowance,
    outstandingNEWOv1Tokens,
  ]);

  useEffect(() => {
    const tokenV1Contract = new window.web3.eth.Contract(
      tokenV1Abi,
      contracts.TOKENS.NEWO.V1,
    );

    setTokenContract(tokenV1Contract);

    const swapContract = new window.web3.eth.Contract(
      oneWaySwapAbi,
      contracts.CONTRACTS.ONE_WAY_SWAP,
    );

    setOneWaySwapContract(swapContract);

    const slpTokenContract = new window.web3.eth.Contract(
      slpTokenAbi,
      contracts.TOKENS.NEWO_USDC_SLP.V1,
    );

    setSLPTokenInstance(slpTokenContract);
  }, [contracts]);

  useEffect(() => {
    async function getOutstandingNEWOBalance() {
      if (!tokenContract || !address) return;

      const slpTokenBalance = new BigNumber(
        await tokenContract.methods.balanceOf(address as string).call(),
      );

      setOutstandingNEWOv1Tokens(slpTokenBalance.isGreaterThan(0));
    }

    getOutstandingNEWOBalance();
  }, [outstandingSLPtokens, tokenContract, address]);

  useEffect(() => {
    async function getSLPTokenBalance() {
      if (!slpTokenInstance || !address) return;

      const slpTokenBalance = new BigNumber(
        await slpTokenInstance.methods.balanceOf(address as string).call(),
      );

      setOutstandingSLPtokens(slpTokenBalance.isGreaterThan(0));
    }

    getSLPTokenBalance();
  }, [slpTokenInstance, address]);

  useEffect(() => {
    async function getVaultInfo() {
      if (typeof address === 'string' && address.length > 0) {
        setVaultBalance(Number(userVaultBalance));
        setVaultRewards(Number(earned));
        setSLPVaultBalance(Number(slpUserVaultBalance));
        setSLPVaultRewards(Number(slpEarned));
      }
    }

    getVaultInfo();
  }, [address, earned, userVaultBalance, slpEarned, slpUserVaultBalance]);

  const swap = async (amount: string) => {
    const tag = 'V2 Swap';

    setLoading(true);

    const convertedAmount = window.web3.utils.toWei(amount, 'ether');

    try {
      await oneWaySwapContract?.methods
        .swap(convertedAmount)
        .send({ from: address });

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Tokens swapped successfully',
        lifetime: 5000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Token swap failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      if (updateTokenBalance) {
        updateTokenBalance();
      }

      setLoading(false);
    }
  };

  const setSwapAllowance = async () => {
    const swapAllowance = await tokenContract?.methods
      .allowance(address ?? '', contracts.CONTRACTS.ONE_WAY_SWAP)
      .call({ from: address });

    const convertedAllowance = window.web3.utils.toWei(swapAllowance, 'wei');

    setAllowance(convertedAllowance);
  };

  const approve = async () => {
    const tag = 'Token Approval';

    setLoading(true);

    try {
      await tokenContract?.methods
        .approve(contracts.CONTRACTS.ONE_WAY_SWAP, MAX_INTEGER)
        .send({ from: address });

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Tokens approved successfully',
        lifetime: 5000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Token approval failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      await setSwapAllowance();

      setLoading(false);
    }
  };

  useEffect(() => {
    if (tokenContract) {
      setSwapAllowance();
    }
    // eslint-disable-next-line
  }, [tokenContract]);

  const burn = async (amount: string, why: string) => {
    const isWalletContract = await isContract(address);
    const tag = 'V2 Swap';

    if (isWalletContract) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: "The wallet address you're using is a smart contract",
        lifetime: 10000,
        tag,
      });

      return;
    }

    // This function burns the tokens of the user
    setLoading(true);

    const convertedAmount = window.web3.utils.toWei(amount, 'ether');

    try {
      await oneWaySwapContract?.methods
        .burn(convertedAmount, why)
        .send({ from: address });

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description:
          'Tokens swapped successfully. Please wait for 24 hours for you to receive your airdrop on avalanche',
        lifetime: 10000,
        tag,
      });
    } catch (err) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Token swap failed. Please try again.',
        lifetime: 5000,
        tag,
      });
    } finally {
      if (updateTokenBalance) {
        updateTokenBalance();
      }

      setLoading(false);
    }
  };

  return {
    swap,
    approve,
    allowance,
    outstandingDepositsOrRewards,
    outstandingSLPtokens,
    outstandingNEWOv1Tokens,
    swapReady,
    loading,
    vaultBalance,
    vaultRewards,
    slpBalance,
    slpRewards,
    burn,
  };
};

export default useOneWaySwap;
