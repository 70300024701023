import React, { useContext, useState } from 'react';
import {
  Button,
  LabelButton,
  NOTIFICATION_TYPES,
  showNotification,
  TextField,
} from 'components/UILib';
import useVault from 'hooks/useVault';
import { Web3Context } from 'components/Web3Provider';
import LoadingOverlay from 'react-loading-overlay';
import useVeVault from 'hooks/useVeVault';
import s from './Vault.module.scss';

interface Token {
  address: string;
  coingeckoId: string;
}

interface VaultProps {
  vaultAddress: string;
  tokenAddress: string;
  vaultLabel: string;
  token0?: Token;
  token1?: Token;
}

const Vault: React.FC<VaultProps> = ({
  vaultAddress,
  tokenAddress,
  vaultLabel,
  token0,
  token1,
}) => {
  const { address } = useContext(Web3Context) || {};

  const {
    userVaultBalance,
    vaultBalance,
    tokenBalance,
    loading,
    apr,
    tokenSymbol,
    stake,
    allowance,
    approve,
  } = useVault(vaultAddress, tokenAddress, token0, token1);
  const [deposit, setDeposit] = useState('');

  const onMax = () => {
    setDeposit(tokenBalance);
  };

  const onSubmit = () => {
    if (Number(deposit) > Number(allowance) || Number(allowance) === 0) {
      approve();
    } else {
      if (Number(deposit) === 0 || !deposit) {
        const tag = 'Deposit';

        showNotification({
          type: NOTIFICATION_TYPES.ERROR,
          description: 'Deposit failed, cannot deposit 0',
          lifetime: 5000,
          tag,
        });

        return;
      }

      stake(deposit);
    }
  };

  // Temporary gonna disable lp vaults until we fix the issue on rewards
  // TODO @arjanvillon
  const isLpVault =
    vaultLabel.toLowerCase().includes('sushi') ||
    vaultLabel.toLowerCase().includes('trader');
  const isLegacyVault = vaultLabel.toLowerCase().includes('legacy');

  if (isLpVault) return null;

  return (
    <LoadingOverlay
      active={loading}
      spinner
      className={s.stakingContainer}
      text={loading}
    >
      <div className={s.tile}>
        <div className={s.tilePart}>
          <div className={s.currency}>{vaultLabel}</div>
          <div className={s.strategy}>APR</div>
          <div className={s.percentValue}>
            {!apr || apr === 'Infinity' || isLpVault || isLegacyVault ? (
              <span>NaN</span>
            ) : (
              <>
                <span>{apr}</span>
                <span className={s.percent}>%</span>
              </>
            )}
          </div>
        </div>
        <div className={s.tilePart}>
          <TextField
            id={`deposit-${vaultAddress}`}
            name="deposit"
            value={deposit}
            onChange={setDeposit}
            label={`Deposit Amount (${tokenSymbol})`}
            rightElement={
              <LabelButton
                onClick={onMax}
                disabled={!!address === false}
                className={s.maxButton}
              >
                MAX
              </LabelButton>
            }
          />

          <div className={s.balances}>
            <div className={s.balance}>
              <span>Your balance: </span>
              <span className={s.balanceValue}>
                {Number(tokenBalance).toFixed(4)} {tokenSymbol}
              </span>
            </div>
            <div className={s.balance}>
              <span>Your deposit: </span>
              <span className={s.balanceValue}>
                {Number(userVaultBalance).toFixed(4)} {tokenSymbol}
              </span>
            </div>
            <div className={s.balance}>
              <span>Total Vault Balance: </span>
              <span className={s.balanceValue}>
                {Number(vaultBalance).toFixed(4)} {tokenSymbol}
              </span>
            </div>
          </div>

          <Button
            className={s.depositButton}
            size="md"
            onClick={onSubmit}
            disabled={!address || isLpVault || isLegacyVault}
          >
            {Number(deposit) > Number(allowance) || Number(allowance) === 0
              ? 'Approve'
              : 'Deposit'}
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export const VeVault: React.FC<VaultProps> = ({
  vaultAddress,
  tokenAddress,
  vaultLabel,
  token0,
  token1,
}) => {
  const { address } = useContext(Web3Context) || {};

  const {
    userVaultBalance,
    vaultBalance,
    tokenBalance,
    loading,
    apr,
    tokenSymbol,
    deposit: depositFn,
    allowance,
    approve,
  } = useVeVault(vaultAddress, tokenAddress, token0, token1);
  const [deposit, setDeposit] = useState('');

  const onMax = () => {
    setDeposit(tokenBalance);
  };

  const onSubmit = () => {
    if (Number(deposit) > Number(allowance) || Number(allowance) === 0) {
      approve();
    } else {
      if (Number(deposit) === 0 || !deposit) {
        const tag = 'Deposit';

        showNotification({
          type: NOTIFICATION_TYPES.ERROR,
          description: 'Deposit failed, cannot deposit 0',
          lifetime: 5000,
          tag,
        });

        return;
      }

      depositFn(deposit);
    }
  };

  // Temporary gonna disable lp vaults until we fix the issue on rewards
  // TODO @arjanvillon
  const isLpVault =
    vaultLabel.toLowerCase().includes('sushi') ||
    vaultLabel.toLowerCase().includes('trader');

  if (isLpVault) return null;

  return (
    <LoadingOverlay
      active={loading}
      spinner
      className={s.stakingContainer}
      text={loading}
    >
      <div className={s.tile}>
        <div className={s.tilePart}>
          <div className={s.currency}>{vaultLabel}</div>
          <div className={s.strategy}>APR</div>
          <div className={s.percentValue}>
            {!apr || apr === 'Infinity' || isLpVault ? (
              <span>NaN</span>
            ) : (
              <>
                <span>{apr}</span>
                <span className={s.percent}>%</span>
              </>
            )}
          </div>
        </div>
        <div className={s.tilePart}>
          <TextField
            id={`deposit-${vaultAddress}`}
            name="deposit"
            value={deposit}
            onChange={setDeposit}
            label={`Deposit Amount (${tokenSymbol})`}
            rightElement={
              <LabelButton
                onClick={onMax}
                disabled={!!address === false}
                className={s.maxButton}
              >
                MAX
              </LabelButton>
            }
          />

          <div className={s.balances}>
            <div className={s.balance}>
              <span>Your balance: </span>
              <span className={s.balanceValue}>
                {Number(tokenBalance).toFixed(4)} {tokenSymbol}
              </span>
            </div>
            <div className={s.balance}>
              <span>Your deposit: </span>
              <span className={s.balanceValue}>
                {Number(userVaultBalance).toFixed(4)} {tokenSymbol}
              </span>
            </div>
            <div className={s.balance}>
              <span>Total Vault Balance: </span>
              <span className={s.balanceValue}>
                {Number(vaultBalance).toFixed(4)} {tokenSymbol}
              </span>
            </div>
          </div>

          <Button
            className={s.depositButton}
            size="md"
            onClick={onSubmit}
            disabled={!address || isLpVault}
          >
            {Number(deposit) > Number(allowance) || Number(allowance) === 0
              ? 'Approve'
              : 'Deposit'}
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Vault;
