import ethereumIcon from '../images/networks/ethereum.jpeg';
import avalancheIcon from '../images/networks/avalanche.jpeg';
import arbitrumIcon from '../images/networks/arbitrum.png';

export interface Network {
  name: string;
  icon: string;
  chainId: number;
  rpcUrl: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExplorerUrl: string;
  type: 'mainnet' | 'testnet';
}

// Get more networks here https://chainid.network/chains.json
const networks: Network[] = [
  {
    name: 'Ethereum',
    icon: ethereumIcon,
    chainId: 1,
    rpcUrl: 'https://main-light.eth.linkpool.io',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrl: 'https://etherscan.io',
    type: 'mainnet',
  },
  {
    name: 'Avalanche',
    icon: avalancheIcon,
    chainId: 43114,
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    blockExplorerUrl: 'https://snowtrace.io',
    type: 'mainnet',
  },
  // {
  //   name: 'Arbitrum',
  //   icon: arbitrumIcon,
  //   chainId: 42161,
  //   rpcUrl: 'https://arb1.arbitrum.io/rpc',
  //   nativeCurrency: {
  //     name: 'Ether',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   blockExplorerUrl: 'https://arbiscan.io/',
  //   type: 'mainnet',
  // },
  {
    name: 'Rinkeby',
    icon: ethereumIcon,
    chainId: 4,
    rpcUrl: 'https://rinkeby-light.eth.linkpool.io',
    nativeCurrency: {
      name: 'Rinkeby Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrl: 'https://rinkeby.etherscan.io',
    type: 'testnet',
  },
  {
    name: 'Avalanche Fuji Testnet',
    icon: avalancheIcon,
    chainId: 43113,
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    blockExplorerUrl: 'https://testnet.snowtrace.io/',
    type: 'testnet',
  },
  // {
  //   name: 'Arbitrum Testnet',
  //   icon: arbitrumIcon,
  //   chainId: 421611,
  //   rpcUrl: 'https://rinkeby.arbitrum.io/rpc',
  //   nativeCurrency: {
  //     name: 'Ether',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   blockExplorerUrl: 'https://rinkeby-explorer.arbitrum.io/#/',
  //   type: 'testnet',
  // },
];

export default networks;
