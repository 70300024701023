import { Web3Context } from 'components/Web3Provider';
import { useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import tokenV2Abi from '../constants/abi/TokenV2.json';
import { TokenV2 } from '../constants/abi/types/TokenV2';

window.web3 = new Web3(window.ethereum);

interface UseTokenData {
  balance: string;
  updateBalance: () => void;
  resetBalance: () => void;
}

const useToken = (tokenAddress: string): UseTokenData => {
  const { address } = useContext(Web3Context) || {};
  const [tokenContract, setTokenContract] = useState<TokenV2 | null>(null);
  const [balance, setBalance] = useState('');

  useEffect(() => {
    if (tokenAddress) {
      const tokenInstance = new window.web3.eth.Contract(
        tokenV2Abi,
        tokenAddress,
      );

      setTokenContract(tokenInstance);
    }
  }, [tokenAddress]);

  const updateBalance = async () => {
    if (!address || !tokenContract) return;

    const accountBalance = await tokenContract?.methods
      .balanceOf(address as string)
      .call();

    const convertedAccountBalance = window.web3.utils.fromWei(
      accountBalance,
      'ether',
    );

    setBalance(convertedAccountBalance);
  };

  useEffect(() => {
    if (tokenContract && address) {
      updateBalance();
    }

    // eslint-disable-next-line
  }, [tokenContract, address]);

  const resetBalance = () => {
    setBalance('');
  };

  return {
    balance,
    updateBalance,
    resetBalance,
  };
};

export default useToken;
