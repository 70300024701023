import React, { useContext, useState } from 'react';
import { Button, Icon, TextField, LabelButton } from 'components/UILib';
import { Web3Context } from 'components/Web3Provider';
import useVault from 'hooks/useVault';
import useVeVault from 'hooks/useVeVault';
import Modal, { useModal } from 'components/Modal';
import LoadingOverlay from 'react-loading-overlay';

import s from '../Deposits.module.scss';

const usePartialWithdrawModal = (
  tokenSymbol: string,
  userVaultBalance: string,
  withdraw: (amount: string) => Promise<void>,
  loading: boolean,
) => {
  // A hook that shows the witdraw modal so the users can withdraw any amount
  const { address } = useContext(Web3Context);
  const [withdrawAmount, setWithdrawAmount] = useState('');

  const onMax = () => {
    setWithdrawAmount(userVaultBalance);
  };

  const [showWithdrawModal, hideWithdrawModal] = useModal(() => (
    <Modal className={s.withdrawModal}>
      <LoadingOverlay active={loading} spinner text="Transaction Pending ...">
        <div className={s.withdrawModalContent}>
          <h1>Withdraw {tokenSymbol}</h1>

          <hr />

          <div className={s.withdrawDetails}>
            <p>Current Deposit: </p>
            <p>{Number(userVaultBalance).toFixed(4)}</p>
          </div>

          <TextField
            name="slpWithdraw"
            value={withdrawAmount}
            onChange={setWithdrawAmount}
            label="Withdraw amount"
            rightElement={
              <LabelButton
                onClick={onMax}
                disabled={!!address === false}
                className={s.maxButton}
              >
                MAX
              </LabelButton>
            }
          />

          <div className={s.withdrawButtons}>
            <Button variant="monochrome" onClick={hideWithdrawModal}>
              Cancel
            </Button>
            <Button
              onClick={() => withdraw(withdrawAmount)}
              disabled={Number(withdrawAmount) <= 0}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </LoadingOverlay>
    </Modal>
  ));

  return { showWithdrawModal };
};

interface Token {
  address: string;
  coingeckoId: string;
}

interface DepositProps {
  label: string;
  tokenAddress: string;
  vaultAddress: string;
  token0?: Token;
  token1?: Token;
}

const Deposit: React.FC<DepositProps> = ({
  label,
  tokenAddress,
  vaultAddress,
  token0,
  token1,
}) => {
  const { address } = useContext(Web3Context);
  const vault = useVault(vaultAddress, tokenAddress, token0, token1);

  const { showWithdrawModal } = usePartialWithdrawModal(
    vault.tokenSymbol,
    vault.userVaultBalance,
    vault.withdraw,
    vault.loading,
  );

  // Temporary gonna disable lp vaults until we fix the issue on rewards
  // TODO @arjanvillon
  const isLpVault =
    label.toLowerCase().includes('sushi') ||
    label.toLowerCase().includes('trader');
  const isLegacyVault = label.toLowerCase().includes('legacy');

  return (
    <tr>
      <td>{label}</td>
      <td>{Number(vault.userVaultBalance).toFixed(4)}</td>
      <td>{isLpVault ? '0' : Number(vault.earned).toFixed(4)}</td>
      <td>{isLpVault || isLegacyVault ? 'NaN' : vault.apr || 0}%</td>
      <td>
        <Button
          variant="monochrome"
          disabled={!address || Number(vault.userVaultBalance) === 0}
          onClick={() => showWithdrawModal()}
          leftElement={<Icon name="withdraw" />}
        >
          Withdraw
        </Button>
        <Button
          variant="monochrome"
          disabled={!address || Number(vault.earned) === 0 || isLpVault}
          onClick={vault.getReward}
          leftElement={<Icon name="withdraw" />}
        >
          Claim Rewards
        </Button>
        <Button
          variant="monochrome"
          disabled={
            !address ||
            (Number(vault.userVaultBalance) === 0 &&
              Number(vault.earned) === 0) ||
            isLpVault
          }
          onClick={vault.exit}
          leftElement={<Icon name="withdraw" />}
        >
          Withdraw & Claim
        </Button>
      </td>
    </tr>
  );
};

export const VeDeposit: React.FC<DepositProps> = ({
  label,
  tokenAddress,
  vaultAddress,
  token0,
  token1,
}) => {
  const { address } = useContext(Web3Context);
  const vault = useVeVault(vaultAddress, tokenAddress, token0, token1);

  const { showWithdrawModal } = usePartialWithdrawModal(
    vault.tokenSymbol,
    vault.userVaultBalance,
    vault.withdraw,
    vault.loading,
  );

  // Temporary gonna disable lp vaults until we fix the issue on rewards
  // TODO @arjanvillon
  const isLpVault =
    label.toLowerCase().includes('sushi') ||
    label.toLowerCase().includes('trader');

  return (
    <tr>
      <td>{label}</td>
      <td>{Number(vault.userVaultBalance).toFixed(4)}</td>
      <td>{isLpVault ? '0' : Number(vault.earned).toFixed(4)}</td>
      <td>{isLpVault ? 'NaN' : vault.apr || 0}%</td>
      <td>
        <Button
          variant="monochrome"
          disabled={!address || Number(vault.userVaultBalance) === 0}
          onClick={() => showWithdrawModal()}
          leftElement={<Icon name="withdraw" />}
        >
          Withdraw
        </Button>
        <Button
          variant="monochrome"
          disabled={!address || Number(vault.earned) === 0 || isLpVault}
          onClick={vault.getReward}
          leftElement={<Icon name="withdraw" />}
        >
          Claim Rewards
        </Button>
        <Button
          variant="monochrome"
          disabled={
            !address ||
            (Number(vault.userVaultBalance) === 0 &&
              Number(vault.earned) === 0) ||
            isLpVault
          }
          onClick={vault.exit}
          leftElement={<Icon name="withdraw" />}
        >
          Withdraw & Claim
        </Button>
      </td>
    </tr>
  );
};

export default Deposit;
