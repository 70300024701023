function numberReduction(number: number): string {
  const notations = [
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
    { value: 1, symbol: '' },
  ];
  const item = notations.find((notation) => number >= notation.value);

  return item ? `${(number / item.value).toFixed(4)}${item.symbol}` : '0';
}

export default numberReduction;
