export const SNAPSHOT_SPACE = 'neworderdao.xyz'; // REACT_APP_SNAPSHOT_SPACE
export const HUB_URL = 'https://hub.snapshot.org'; // REACT_APP_HUB_URL

export const DAY_IN_SECONDS = 86_400;
export const YEAR_IN_SECONDS = 31_536_000; // yearInSeconds
export const RATE_PER_SECOND_PER_TOKEN = 6_341_958_000;

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export const MERKLE_SMART_CONTRACT_ADDRESS =
  '0x92491C8871ef7E06921f29238674CaF54652f218';

export const PRODUCTION = 'mainnet';
export const DEVELOPMENT = 'rinkeby';

interface NetworkVersion {
  V1: string;
  V2: string;
}

interface Token {
  address: string;
  coingeckoId: string;
}

interface Vault {
  label: string;
  vaultAddress: string;
  tokenAddress: string;
  token0?: Token;
  token1?: Token;
}

export interface NetworkContract {
  TOKENS: { NEWO: NetworkVersion; NEWO_USDC_SLP: NetworkVersion; USDC: string };
  CONTRACTS: {
    SINGLE_SIDE_STAKING: NetworkVersion;
    SUSHI_LP_STAKING: NetworkVersion;
    GOVERNANCE_STAKING: NetworkVersion;
    ONE_WAY_SWAP: string;
    MERKLE_TREE: string;
  };
  VAULTS: Vault[];
}

export const NETWORK = {
  1: {
    TOKENS: {
      NEWO: {
        V1: '0x1b890fd37cd50bea59346fc2f8ddb7cd9f5fabd5', // REACT_APP_TOKEN_ADDRESS
        V2: '0x98585dFc8d9e7D48F0b1aE47ce33332CF4237D96', // REACT_APP_V2_TOKEN_ADDRESS
      },
      NEWO_USDC_SLP: {
        V1: '0xb264dc9d22ece51aaa6028c5cbf2738b684560d6', // REACT_APP_SLP_TOKEN_ADDRESS
        V2: '0xc08ED9a9ABEAbcC53875787573DC32Eee5E43513', // REACT_APP_V2_SLP_TOKEN_ADDRESS
      },
      USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // REACT_APP_USDC_TOKEN_ADDRESS | REACT_APP_BAL_ODEFI_USDC
    },
    CONTRACTS: {
      SINGLE_SIDE_STAKING: {
        V1: '0x798fcFc792e7Aa09C0E08f3395BDA5A75C3295F7', // REACT_APP_BAL_ODEFI_RP0_Vault
        V2: '0xBC9016C379fb218B95Fe3730D5F49F3149E86CAB', // REACT_APP_V2_VAULT_ADDRESS
      },
      SUSHI_LP_STAKING: {
        V1: '0x9D4af0f08B300437b4f0d97A1C5c478F1e0A7D3C', // REACT_APP_SLP_VAULT
        V2: '0xdb36b23964FAB32dCa717c99D6AEFC9FB5748f3a', // REACT_APP_V2_SLP_VAULT_ADDRESS
      },
      GOVERNANCE_STAKING: {
        V1: '0x6d8EE285939F6254D36C09b66fc6244d05006b28', // REACT_APP_STAKING_ADDRESS
        V2: '0x0f28C58cd97270a2FdEBC89462A405D7a5A56981', // *** TODO ***
      },
      ONE_WAY_SWAP: '0xb3c60348A8f8eD8B260FD1966c9f2b740e6caedF', // REACT_APP_ONE_WAY_SWAP_ADDRESS
      MERKLE_TREE: '0x92491C8871ef7E06921f29238674CaF54652f218', // REACT_APP_MERKLE_SMART_CONTRACT_ADDRESS
    },
    VAULTS: [
      // {
      //   label: 've LP Vault',
      //   vaultAddress: '0x86e8062c9F9e5f852bB0D8fc3f33fc110E9b2e47',
      //   tokenAddress: '0xc08ED9a9ABEAbcC53875787573DC32Eee5E43513',
      //   token0: {
      //     address: '0x98585dfc8d9e7d48f0b1ae47ce33332cf4237d96',
      //     coingeckoId: 'new-order',
      //   },
      //   token1: {
      //     address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      //     coingeckoId: 'usd-coin',
      //   },
      // },
      {
        label: 'Legacy NEWO Single-Side',
        vaultAddress: '0xBC9016C379fb218B95Fe3730D5F49F3149E86CAB',
        tokenAddress: '0x98585dFc8d9e7D48F0b1aE47ce33332CF4237D96',
      },
      {
        label: 'Legacy Sushi LP - NEWO/USDC',
        vaultAddress: '0xdb36b23964FAB32dCa717c99D6AEFC9FB5748f3a',
        tokenAddress: '0xc08ED9a9ABEAbcC53875787573DC32Eee5E43513',
        token0: {
          address: '0x98585dfc8d9e7d48f0b1ae47ce33332cf4237d96',
          coingeckoId: 'new-order',
        },
        token1: {
          address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
          coingeckoId: 'usd-coin',
        },
      },
    ],
  },
  4: {
    TOKENS: {
      NEWO: {
        V1: '0xe12Acac280EAa9CA7FacFd567f22271Feac682A9', // REACT_APP_TOKEN_ADDRESS
        V2: '0x3597e30d8fa8f9a9fd5db8a6fafe872c8e720b14', // REACT_APP_V2_TOKEN_ADDRESS
      },
      NEWO_USDC_SLP: {
        V1: '0x99741D302309254E22aB4f02fE27eb00269eC1c2', // REACT_APP_SLP_TOKEN_ADDRESS
        V2: '0xc08ED9a9ABEAbcC53875787573DC32Eee5E43513', // REACT_APP_V2_SLP_TOKEN_ADDRESS
      },
      USDC: '0xeb8f08a975ab53e34d8a0330e0d34de942c95926', // REACT_APP_USDC_TOKEN_ADDRESS | REACT_APP_BAL_ODEFI_USDC=0x7Ac723C93c6B33D007DaA95fE0b739c1a7323382
    },
    CONTRACTS: {
      SINGLE_SIDE_STAKING: {
        V1: '0x35C3d70fCd15AE6E6069a3370b025bbB42a115d9', // REACT_APP_BAL_ODEFI_RP0_Vault
        V2: '0x2Af1Ba23Fb59D3e1A307A6b487625130b7fE1f67', // REACT_APP_V2_VAULT_ADDRESS
      },
      SUSHI_LP_STAKING: {
        V1: '0x82FC329A6F2c63170EE73fBc2Fe87362BA975024', // REACT_APP_SLP_VAULT
        V2: '0xdb36b23964FAB32dCa717c99D6AEFC9FB5748f3a', // REACT_APP_V2_SLP_VAULT_ADDRESS
      },
      GOVERNANCE_STAKING: {
        V1: '0x15dbf97FcC22220a85D49D268f7efa0e8cA21595', // REACT_APP_STAKING_ADDRESS
        V2: '0x334ae841884b356b99Bb2169f85E1779Ab572e93', // *** TODO ***
      },
      ONE_WAY_SWAP: '0xa1d3ee509c25287153ccd057973b82b8bc49bc15', // REACT_APP_ONE_WAY_SWAP_ADDRESS
      MERKLE_TREE: '0x992b81309b8A5E854725573237C3b99C514b0C55', // REACT_APP_MERKLE_SMART_CONTRACT_ADDRESS
    },
    VAULTS: [
      {
        label: 'NEWO Single-Side',
        vaultAddress: '0x2Af1Ba23Fb59D3e1A307A6b487625130b7fE1f67',
        tokenAddress: '0x3597e30d8fa8f9a9fd5db8a6fafe872c8e720b14',
      },
      {
        label: 'Sushi LP - NEWO/USDC',
        vaultAddress: '0x4D377f73e1Ec14BC5A898275286E55A6C9D2FEca',
        tokenAddress: '0x09432f93e8CFCb1df310585dfb8208C1A3FC9547',
        token0: {
          address: '0x3597e30d8fa8f9a9fd5db8a6fafe872c8e720b14',
          coingeckoId: 'new-order',
        },
        token1: {
          address: '0xeb8f08a975ab53e34d8a0330e0d34de942c95926',
          coingeckoId: 'usd-coin',
        },
      },
    ],
  },
  43114: {
    TOKENS: {
      NEWO: {
        V1: '',
        V2: '0x4Bfc90322dD638F81F034517359BD447f8E0235a',
      },
      NEWO_USDC_SLP: {
        V1: '',
        V2: '',
      },
      USDC: '',
    },
    CONTRACTS: {
      SINGLE_SIDE_STAKING: {
        V1: '',
        V2: '0x27c61A04f5c0F28764ADF926f99efCf9D15e9c2d',
      },
      SUSHI_LP_STAKING: {
        V1: '',
        V2: '',
      },
      GOVERNANCE_STAKING: {
        V1: '',
        V2: '',
      },
      ONE_WAY_SWAP: '',
      MERKLE_TREE: '',
    },
    VAULTS: [
      // {
      //   label: 've LP Vault',
      //   vaultAddress: '0xD56cA252facD3d45Ad61D9700322E639d4eF5A7A',
      //   tokenAddress: '0x3931589c6C078524d1FA88E4d86D2F1aC9fEd56d',
      //   token0: {
      //     address: '0x4Bfc90322dD638F81F034517359BD447f8E0235a',
      //     coingeckoId: 'new-order',
      //   },
      //   token1: {
      //     address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      //     coingeckoId: 'wrapped-avax',
      //   },
      // },
      {
        label: 'Legacy NEWO Single-Side',
        vaultAddress: '0x27c61A04f5c0F28764ADF926f99efCf9D15e9c2d',
        tokenAddress: '0x4Bfc90322dD638F81F034517359BD447f8E0235a',
      },
      {
        label: 'Legacy Trader Joe LP - NEWO/WAVAX',
        vaultAddress: '0xCDCa2C226eA55ed11e1CDCFf964AfB26A198bB0C',
        tokenAddress: '0x3931589c6C078524d1FA88E4d86D2F1aC9fEd56d',
        token0: {
          address: '0x4Bfc90322dD638F81F034517359BD447f8E0235a',
          coingeckoId: 'new-order',
        },
        token1: {
          address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
          coingeckoId: 'wrapped-avax',
        },
      },
    ],
    ONE_WAY_SWAP: '0xb3c60348A8f8eD8B260FD1966c9f2b740e6caedF', // REACT_APP_ONE_WAY_SWAP_ADDRESS
    MERKLE_TREE: '0x92491C8871ef7E06921f29238674CaF54652f218', // REACT_APP_MERKLE_SMART_CONTRACT_ADDRESS
  },
  43113: {
    TOKENS: {
      NEWO: {
        V1: '0xe12Acac280EAa9CA7FacFd567f22271Feac682A9', // Not real value
        V2: '0x82f2B30dF50b18a1AA06b2154C307E7f97a16250',
      },
      NEWO_USDC_SLP: {
        V1: '',
        V2: '0x35C3d70fCd15AE6E6069a3370b025bbB42a115d9',
      },
      USDC: '0xaf82969ecf299c1f1bb5e1d12ddacc9027431160',
    },
    CONTRACTS: {
      SINGLE_SIDE_STAKING: {
        V1: '',
        V2: '0xfD264BdE9C5F943D7eF6Ba2851ecB0F709c765EC',
      },
      SUSHI_LP_STAKING: {
        V1: '',
        V2: '0xA1d3EE509c25287153cCD057973b82b8bc49BC15',
      },
      GOVERNANCE_STAKING: {
        V1: '',
        V2: '',
      },
      ONE_WAY_SWAP: '',
      MERKLE_TREE: '',
    },
    ONE_WAY_SWAP: '0xa1d3ee509c25287153ccd057973b82b8bc49bc15', // REACT_APP_ONE_WAY_SWAP_ADDRESS
    MERKLE_TREE: '0x992b81309b8A5E854725573237C3b99C514b0C55', // REACT_APP_MERKLE_SMART_CONTRACT_ADDRESS
  },
  // 42161: {
  //   TOKENS: {
  //     NEWO: {
  //       V1: '',
  //       V2: '0x0877154a755B24D499B8e2bD7ecD54d3c92BA433',
  //     },
  //     NEWO_USDC_SLP: {
  //       V1: '',
  //       V2: '',
  //     },
  //     USDC: '',
  //   },
  //   CONTRACTS: {
  //     SINGLE_SIDE_STAKING: {
  //       V1: '',
  //       V2: '',
  //     },
  //     SUSHI_LP_STAKING: {
  //       V1: '',
  //       V2: '',
  //     },
  //     GOVERNANCE_STAKING: {
  //       V1: '',
  //       V2: '',
  //     },
  //     ONE_WAY_SWAP: '',
  //     MERKLE_TREE: '',
  //   },
  // },
  // 421611: {
  //   TOKENS: {
  //     NEWO: {
  //       V1: '0xe12Acac280EAa9CA7FacFd567f22271Feac682A9', // Not real value
  //       V2: '0x82f2B30dF50b18a1AA06b2154C307E7f97a16250',
  //     },
  //     NEWO_USDC_SLP: {
  //       V1: '',
  //       V2: '0x35C3d70fCd15AE6E6069a3370b025bbB42a115d9',
  //     },
  //     USDC: '0xb0Ad46bD50b44cBE47E2d83143E0E415d6A842F6',
  //   },
  //   CONTRACTS: {
  //     SINGLE_SIDE_STAKING: {
  //       V1: '',
  //       V2: '0xfD264BdE9C5F943D7eF6Ba2851ecB0F709c765EC',
  //     },
  //     SUSHI_LP_STAKING: {
  //       V1: '',
  //       V2: '0xA1d3EE509c25287153cCD057973b82b8bc49BC15',
  //     },
  //     GOVERNANCE_STAKING: {
  //       V1: '',
  //       V2: '',
  //     },
  //     ONE_WAY_SWAP: '',
  //     MERKLE_TREE: '',
  //   },
  // },
};
