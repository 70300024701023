import { logger } from 'services';
import coinGeckoApi from '../api/coingeckoApi';

export const getTokenPrice = async (coingeckoId: string): Promise<number> => {
  try {
    const res = await coinGeckoApi.get(`/coins/${coingeckoId}`);

    return res.data.market_data.current_price.usd;
  } catch (err) {
    logger.log(err);

    return 0;
  }
};
