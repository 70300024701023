/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-return-assign */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState, useContext } from 'react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { logger } from 'services';
import { Proposal } from 'features/voting/types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useModal } from 'components/Modal/useModal';
import Modal from 'components/Modal';
import LoadingOverlay from 'react-loading-overlay';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import Staking from 'components/Staking';
import useVault from 'hooks/useVault';
import Client from '../../helpers/snapshot/client';
import { Web3Context } from '../../components/Web3Provider';
import { apolloClient } from '../../helpers/apollo';
import { RootState } from '../../store/types';
import { selectVoteById } from '../../store/voting';
import { ROUTES } from '../../constants/routingConstants';

import { VOTES_QUERY, PROPOSAL_QUERY } from '../../helpers/queries';
import {
  BackButton,
  PageTitle,
  TagButton,
  TileContainer,
  MemberHash,
  Button,
  NOTIFICATION_TYPES,
  Icon,
} from '../../components/UILib';
import { VoteDate } from '../../components/VoteDate';
import { TileInfoBlock } from './components/TileInfoBlock';
import { showNotification } from '../../components/UILib/notifications/notificationUtils';

import s from './SingleVotePage.module.scss';

import { HUB_URL, SNAPSHOT_SPACE } from '../../constants';

const hubUrl = HUB_URL;

const stakingClient = new Staking();

export const SingleVotePage: React.FC = () => {
  const [proposal, setProposal] = useState<Proposal>();
  const [results, setResults] = useState<any>();
  const [selectedChoices, setSelectedChoices] = useState<string[]>([]);
  const [votePending, setVotePending] = useState(false);
  const [voteIn, setVoteIn] = useState(0);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isConnected, address, contracts } = useContext(Web3Context) || {};
  const [votingPower, setVotingPower] = useState(0);
  const vote = useSelector((state: RootState) => selectVoteById(state, id));
  const voteHref = `https://snapshot.org/#/${SNAPSHOT_SPACE}/proposal/${id}`;

  const { userVaultBalance } = useVault(
    contracts.CONTRACTS.SINGLE_SIDE_STAKING.V2,
    contracts.TOKENS.NEWO.V2,
  );

  function goBack() {
    history.push(ROUTES.VOTING);
  }

  // eslint-disable-next-line consistent-return
  const getProposal = async (id: string) => {
    try {
      const response = await Promise.all([
        apolloClient.query({
          query: PROPOSAL_QUERY,
          variables: {
            id,
          },
        }),
        apolloClient.query({
          query: VOTES_QUERY,
          variables: {
            id,
          },
        }),
      ]);

      let [proposalQueryResponse, votes] = cloneDeep(response);
      const { proposal } = proposalQueryResponse.data;

      setProposal(proposal);

      let result = {};

      proposal.choices.forEach((choice: string) => {
        result[choice] = 0;
      });

      votes.data.votes.forEach((vote) => {
        const choiceKey = vote.choice - 1;
        const choiceValue = Object.keys(result)[choiceKey];

        result[choiceValue] += vote.vp;
      });

      setResults(result);
    } catch (e) {
      logger.error(e);

      return e;
    }
  };

  useEffect(() => {
    getProposal(id);
  }, [id, voteIn]);

  useEffect(() => {
    async function getStakedVotingPower(who: string) {
      if (!userVaultBalance) return;

      let converted = 0;

      try {
        const stakedVotingPower = await stakingClient.getTotalVotingPower(who);

        converted = stakedVotingPower / 1e18;
      } catch (err) {
        logger.error(err);
      }

      // Add the voting power with the balance from the single side vault
      const totalStakedPower = Number(userVaultBalance) + converted;

      setVotingPower(totalStakedPower);
    }

    if (address) {
      getStakedVotingPower(address);
    }
  }, [address, userVaultBalance]);

  useEffect(
    () => () => {
      logger.log('[CLEANUP]');
    },
    [],
  );

  async function signingMessage(provider, account, space, type, payload) {
    try {
      const snapshotClient = new Client(hubUrl);
      const response = await snapshotClient.broadcast(
        provider,
        account,
        space,
        type,
        payload,
      );

      return response;
    } catch (e) {
      return e;
    }
  }

  async function handleSubmitVote() {
    const tag = 'vote';

    setVotePending(true);
    await window.ethereum.send('eth_requestAccounts');

    let choiceIndex;

    if (proposal && proposal.type === 'approval') {
      choiceIndex = [];
      selectedChoices.map((choice) =>
        choiceIndex.push(proposal.choices.indexOf(choice) + 1),
      );
    } else {
      choiceIndex =
        proposal && proposal.choices.indexOf(selectedChoices[0]) + 1;
    }

    let space = SNAPSHOT_SPACE;
    let msg = {
      proposal: proposal?.id,
      choice: choiceIndex,
      metadata: {},
    };
    let result = await signingMessage(
      window.ethereum,
      address,
      space,
      tag,
      msg,
    );

    setVotePending(false);

    // @ts-ignore
    if (result.ipfsHash) {
      hideModal();
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        description: 'Your vote has been submitted successfully',
        lifetime: 3000,
        tag,
      });

      const newVote = voteIn + 1;

      setTimeout(() => {
        setVoteIn(newVote);
      }, 3000);
    } else {
      hideModal();
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        description: 'Your vote submission failed, please try again later.',
        lifetime: 3000,
        tag,
      });
    }
  }

  const [showModal, hideModal] = useModal(() => (
    <Modal>
      <h1 className={s.modalTitle}>Confirmation</h1>
      <p>
        Are you sure you want to vote &ldquo;
        {selectedChoices.length > 2
          ? `${selectedChoices[0]}, ${selectedChoices[1]}...`
          : `${selectedChoices[0]}`}
        &rdquo;?
        <br /> This action cannot be undone.
      </p>
      <hr />
      <LoadingOverlay
        active={votePending}
        spinner
        // className={s.stakingContainer}
        text="Voting Pending ..."
      >
        <div className={s.confirmModal}>
          <div className={s.col}>
            <p>Choice(s)</p>
            <p>Block #</p>
            <p>Your voting power</p>
          </div>
          <div className={s.col}>
            <p>{selectedChoices.toString()}</p>
            <p>
              {proposal?.snapshot}{' '}
              <a
                rel="noreferrer"
                href={`https://etherscan.io/block/${proposal?.snapshot}`}
                target="_blank"
              >
                <Icon name="more-vert" />
              </a>
            </p>
            <p>{votingPower}</p>
          </div>
        </div>
      </LoadingOverlay>
      <hr />
      <div className={s.modalButton}>
        <Button
          variant="monochrome"
          onClick={() => {
            hideModal();
          }}
          disabled={votePending}
        >
          Cancel
        </Button>
        <Button
          danger
          variant="outline"
          onClick={handleSubmitVote}
          disabled={votingPower <= 0 || votePending}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  ));

  function renderStatusHeader() {
    const { status = '' } = vote || {};

    return (
      <div className={s.title}>
        Status
        <TagButton
          className={s.openClosed}
          color="green"
          uppercase
          badge
          active
        >
          {status}
        </TagButton>
      </div>
    );
  }

  function renderStatusTile() {
    return (
      <TileContainer title={renderStatusHeader()} className={s.statusTile}>
        <div className={s.row}>
          <div className={s.label}>Vote completion date</div>
          <div className={s.value}>
            <VoteDate voteLaunch={vote?.voteLaunch || 0} />
          </div>
        </div>
        <div className={s.row}>
          <div className={s.label}>Vote id</div>
          <div className={s.value}>
            <MemberHash
              className={s.hash}
              hashSum={id}
              size="sm"
              length="short"
              href={voteHref}
            />
          </div>
        </div>
      </TileContainer>
    );
  }

  function renderVotes() {
    return (
      <div className={s.allVotes}>
        {Object.keys(results).map((key) => (
          <div key={key} className={s.voteContainer}>
            <div className={s.values}>
              <div className={s.percents}>
                {key}:{' '}
                <span className={s.votesPercentVal}>
                  {/* {percentageObj[key] || 0}% */}
                </span>
              </div>
              <div>
                {results[key].toFixed(2)} {results[key] <= 1 ? 'vote' : 'votes'}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  function handleChoiceSelect(key) {
    let selected = [...selectedChoices];

    if (proposal?.type === 'single-choice' || proposal?.type === 'basic') {
      if (selected.includes(key)) {
        selected = [];
        setSelectedChoices(selected);
      } else {
        selected = [key];
        setSelectedChoices(selected);
      }
    } else if (proposal?.type === 'approval') {
      const index = selected.indexOf(key);

      if (index > -1) {
        selected.splice(index, 1);
        setSelectedChoices(selected);
      } else {
        selected.push(key);
        setSelectedChoices(selected);
      }
    }
  }

  function renderVoteButtons() {
    return (
      <>
        {Object.keys(results).map((key) => (
          <div key={key} className={s.voteButton}>
            {selectedChoices.includes(key) ? (
              <Button
                variant="regular"
                uppercase
                className={s.button}
                size="xl"
                onClick={() => handleChoiceSelect(key)}
              >
                {key}
              </Button>
            ) : (
              <Button
                variant="outline"
                uppercase
                className={s.button}
                size="xl"
                onClick={() => handleChoiceSelect(key)}
              >
                {key}
              </Button>
            )}
          </div>
        ))}
        <div className={s.submitButton}>
          <Button
            variant="monochrome"
            uppercase
            className={s.button}
            size="xl"
            disabled={selectedChoices.length === 0 || !isConnected}
            onClick={() => showModal()}
          >
            VOTE
          </Button>
        </div>
      </>
    );
  }

  function renderVoteDetails() {
    return (
      <TileContainer
        title={<div className={s.voteTitle}>{vote?.voteTitle}</div>}
        className={s.voteDetails}
      >
        <div className={s.generalInfo}>
          <TileInfoBlock title="Description" className={s.infoBlock}>
            <ReactMarkdown className="react-markdown" remarkPlugins={[gfm]}>
              {vote && vote.voteDescription ? vote.voteDescription : ''}
            </ReactMarkdown>
          </TileInfoBlock>
          <TileInfoBlock title="Created by" className={s.infoBlock}>
            <MemberHash
              hashSum={vote?.author || ''}
              size="sm"
              length="short"
              href={`https://etherscan.io/address/${vote?.author}`}
            />
          </TileInfoBlock>
          <TileInfoBlock title="Vote launch" className={s.infoBlock}>
            <VoteDate voteLaunch={vote?.voteLaunch || 0} />
          </TileInfoBlock>
        </div>
        {/* {proposal?.type !== 'quadratic' && proposal?.state === 'active' ? (
          <TileInfoBlock title="Vote" className={s.voteBlock}>
            {results ? (
              renderVoteButtons()
            ) : (
              <SkeletonTheme color="#202020" highlightColor="#444">
                <p>
                  <Skeleton count={2} />
                </p>
              </SkeletonTheme>
            )}
          </TileInfoBlock>
        ) : null} */}
        <TileInfoBlock title="Result" className={s.voteBlock}>
          {results ? (
            renderVotes()
          ) : (
            <SkeletonTheme color="#202020" highlightColor="#444">
              <p>
                <Skeleton count={2} />
              </p>
            </SkeletonTheme>
          )}
        </TileInfoBlock>
      </TileContainer>
    );
  }

  return (
    <div>
      <PageTitle className={s.header}>
        <div className={s.headerLeftPart}>
          <BackButton onClick={goBack} className={s.backButton}>
            Back
          </BackButton>
          Voting
        </div>
        <Button href={voteHref} target="_blank">
          Snapshot Proposal
        </Button>
      </PageTitle>
      <div className={s.content}>
        {renderStatusTile()}
        {renderVoteDetails()}
      </div>
    </div>
  );
};
