import React, { useContext, useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { SocialMediaBlock } from 'components/SocialMediaBlock';
import { WalletWidget } from 'components/WalletWidget';
import Modal, { useModal } from 'components/Modal';
import { Button, Icon } from 'components/UILib';
import networks, { Network } from 'constants/networks';
import { IS_DEVELOPMENT } from 'constants/index';
import { Web3Context } from 'components/Web3Provider';
import { useHistory } from 'react-router';
import Logo from '../Logo/Logo';
import { NavMenuItem } from './components/NavMenuItem';
import { NavSection } from './components/NavSection';
import { CoverScreen } from './components/CoverScreen';
import { ROUTES } from '../../constants/routingConstants';
import { HEADER_ID, CONTENT_ID } from '../../constants/layoutConstants';

import s from './MainLayout.module.scss';

export interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const history = useHistory();
  const { network, changeChainId } = useContext(Web3Context);
  const [activeNetwork, setActiveNetwork] = useState<Network | null>(null);
  const [showMigration, setShowMigration] = useState(true);
  const chains = !IS_DEVELOPMENT
    ? networks.filter((networkItem) => networkItem.type === 'mainnet')
    : networks;

  const [showNetworkModal, hideNetworkModal] = useModal(() => (
    <Modal className={s.networkModal}>
      <div className={s.modalHeader}>
        <h3>
          {activeNetwork
            ? 'Select Network'
            : "You're connected to an unsupported network"}
        </h3>
        {activeNetwork && (
          <Icon
            name="close"
            onClick={hideNetworkModal}
            className={s.closeIcon}
          />
        )}
      </div>

      <div className={s.networks}>
        {chains.map((chain) => (
          <button
            key={chain.name}
            className={classNames(
              s.network,
              network === chain.chainId && s.activeNetwork,
            )}
            onClick={() => {
              if (changeChainId) {
                changeChainId(chain);
              }
            }}
          >
            <img src={chain.icon} alt={chain.name} className={s.networkIcon} />
            <span>{chain.name}</span>
          </button>
        ))}
      </div>
    </Modal>
  ));

  useEffect(() => {
    const currentNetwork = networks.find(
      (networkItem) => networkItem.chainId === network,
    );

    if (currentNetwork) {
      setActiveNetwork(currentNetwork);
    } else {
      showNetworkModal();
      setActiveNetwork(null);
    }

    if (network !== 4 && network !== 1) {
      setShowMigration(false);

      if (history.location.pathname.includes('migrate')) {
        history.push('/');
      }
    } else {
      setShowMigration(true);
    }
  }, [network, history, showNetworkModal]);

  return (
    <div className={s.root}>
      <CoverScreen />
      <SimpleBar className={s.panelContainer}>
        <aside className={s.panel}>
          <div className={s.panelTop}>
            <div className={s.logoSection}>
              <a href="/" className={s.logoLink}>
                <Logo className={s.logo} />
              </a>
            </div>
            <nav>
              <NavSection header="DAO">
                <NavMenuItem iconName="home" to={ROUTES.HOME}>
                  Home
                </NavMenuItem>
                <NavMenuItem
                  iconName="voting"
                  to={ROUTES.VOTING}
                  altTo={[ROUTES.SINGLE_VOTE]}
                >
                  Voting
                </NavMenuItem>
                {/* <NavMenuItem iconName="members">Members</NavMenuItem> */}
                {/* <NavMenuItem iconName="dao-token">DAO Token</NavMenuItem> */}
                <NavMenuItem iconName="airdrops" to={ROUTES.AIRDROPS}>
                  Airdrops
                </NavMenuItem>

                {showMigration && (
                  <NavMenuItem iconName="reverse" to={ROUTES.MIGRATE}>
                    V2 Migration
                  </NavMenuItem>
                )}
              </NavSection>

              <NavSection header="Marketplace">
                <NavMenuItem iconName="members" to={ROUTES.INVEST}>
                  Vault
                </NavMenuItem>
                <NavMenuItem iconName="venewo" to={ROUTES.VENEWO} isExternal>
                  veNEWO
                </NavMenuItem>
              </NavSection>

              {/* <NavSection header="Settings"> */}
              {/*   <NavMenuItem iconName="permissions">Permissions</NavMenuItem> */}
              {/*   <NavMenuItem iconName="notifications">Notifications</NavMenuItem> */}
              {/*   <NavMenuItem iconName="labels">Labels</NavMenuItem> */}
              {/*   <NavMenuItem iconName="help">Help center</NavMenuItem> */}
              {/* </NavSection> */}
            </nav>
          </div>
          <footer>
            <SocialMediaBlock className={s.socialMedia} />
          </footer>
        </aside>
      </SimpleBar>
      <SimpleBar className={s.content} id={CONTENT_ID}>
        <header className={s.header} id={HEADER_ID}>
          {activeNetwork && (
            <Button
              className={s.networkButton}
              variant="monochrome"
              onClick={showNetworkModal}
            >
              <img
                src={activeNetwork.icon}
                alt={activeNetwork.name}
                className={s.networkIcon}
              />
            </Button>
          )}

          {/* <Balance balanceAmount={1259.12} fillPercent={30} /> */}
          <WalletWidget />
        </header>
        <main className={s.main}>{children}</main>
      </SimpleBar>
    </div>
  );
};

export default MainLayout;
