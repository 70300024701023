const icons = {
  home: import('./home.svg'),
  voting: import('./voting.svg'),
  members: import('./members.svg'),
  'dao-token': import('./dao-token.svg'),
  airdrops: import('./airdrops.svg'),
  finance: import('./finance.svg'),
  strategies: import('./strategies.svg'),
  compare: import('./compare.svg'),
  permissions: import('./permissions.svg'),
  notifications: import('./notifications.svg'),
  labels: import('./labels.svg'),
  help: import('./help.svg'),
  twitter: import('./twitter.svg'),
  telegram: import('./telegram.svg'),
  discord: import('./discord.svg'),
  medium: import('./medium.svg'),
  github: import('./github.svg'),
  bell: import('./bell.svg'),
  'arrow-up': import('./arrow-up.svg'),
  error: import('./error.svg'),
  warning: import('./warning.svg'),
  info: import('./info.svg'),
  success: import('./success.svg'),
  close: import('./close.svg'),
  'bell-dotted': import('./bell-dotted.svg'),
  calendar: import('./calendar.svg'),
  'circle-close': import('./circle-close.svg'),
  transfer: import('./transfer.svg'),
  'more-vert': import('./more-vert.svg'),
  'arrow-upward': import('./arrow-upward.svg'),
  'arrow-downward': import('./arrow-downward.svg'),
  reverse: import('./reverse.svg'),
  'expand-more': import('./expand-more.svg'),
  withdraw: import('./withdraw.svg'),
  deposit: import('./deposit.svg'),
  action: import('./action.svg'),
  'check-mark': import('./check-mark.svg'),
  claimed: import('./claimed.svg'),
  claiming: import('./claiming.svg'),
  'square-twitter': import('./square-twitter.svg'),
  'square-rss': import('./square-rss.svg'),
  'cloud-check': import('./cloud-check.svg'),
  'chevron-right': import('./chevron-right.svg'),
  search: import('./search.svg'),
  mirror: import('./mirror.svg'),
  token: import('./token.svg'),
  ethereum: import('./ethereum.svg'),
  avalanche: import('./avalanche.svg'),
  venewo: import('./venewo.svg'),
};

export default icons;
