import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Web3Context } from 'components/Web3Provider';
import { Tabs } from '../../components/UILib/Tabs';
import { DepositInfo } from './components/DepositInfo';
import { PageTitle } from '../../components/UILib/PageTitle';
import { Notice, Button, Icon } from '../../components/UILib';
import { ActiveDepositsTab } from './components/ActiveDepositsTab';

import s from './InvestPage.module.scss';

export const V2Notice: React.FC = () => {
  const history = useHistory();
  const { network } = useContext(Web3Context);

  if (network !== 1) {
    return null;
  }

  return (
    <Notice className={s.migrationNotice}>
      <h2>Important Notice: NEWO v2 Migration </h2>

      <p style={{ fontSize: '.9rem' }}>
        All NEWO V1 tokens must be migrated manually to the recently released
        NEWO V2. First, Remove NEWO V1 from all single-sided, Sushi LP, and
        governance vaults and claim all outstanding rewards.
      </p>

      <p style={{ fontSize: '.9rem' }}>
        Please refer to our{' '}
        <a
          href="https://mirror.xyz/0x13c5432CfC12bA1f32B6090d1a09cf0Efe9C95Bd/asY4uDk18RDjh5lV2QNxWEaLq88JNTqWDkZcxgG9gi8"
          target="_blank"
          rel="noopener noreferrer"
        >
          NEWO v2 migration guide
        </a>{' '}
        for more information or visit us on discord for support.
      </p>

      <Button
        rightElement={<Icon name="labels" style={{ color: 'black' }} />}
        onClick={function handleClick() {
          history.push('/migrate');
        }}
      >
        Go to Migration Tool
      </Button>
    </Notice>
  );
};

export const InvestPage: React.FC = () => (
  <div className={s.root}>
    <PageTitle className={s.title}>Vault Assets</PageTitle>
    <Tabs
      tabs={[
        {
          id: 'deposit',
          label: 'Deposit',
          content: (
            <div className={s.deposits}>
              <V2Notice />
              <DepositInfo />
            </div>
          ),
        },
        {
          id: 'active-deposits',
          label: 'Active deposits',
          content: (
            <div className={s.deposits}>
              <V2Notice />
              <ActiveDepositsTab />
            </div>
          ),
        },
      ]}
    />
  </div>
);
